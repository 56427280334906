.no-scroll::-webkit-scrollbar {
    display: none;
}

*, html, *:before, *:after {
  box-sizing: border-box;
}
html,
body {
  margin: 0px;
  overflow-x: hidden;
  width: 100vw;
  font-size: 62.5%;
  font-family: 'Aleo', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (min-width: 1300px) {
  html,
  body {
    font-size: 70%;
  }
}
@media screen and (min-width: 1450px) {
  html,
  body {
    font-size: 72%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sans {
  font-family: 'Montserrat', sans-serif;
}
section {
  position: relative;
}
.modal-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 101;
  opacity: 0;
  display: none;
}
.modal-bg.showModal {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 1;
  animation: fadeIn 1s;
}
@media screen and (max-width: 480px) {
  .modal-bg.showModal {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
}
.modal-bg .modal-box {
  position: relative;
  background-color: #fff;
  padding: 15px 15px 60px 15px;
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 480px) {
  .modal-bg .modal-box {
    padding: 0 0 44px 0;
    background-color: #a68202;
  }
}
.modal-bg .modal-box #modal-content {
  position: relative;
  z-index: 102;
}
.modal-bg .modal-box #modal-content .media {
  position: relative;
  width: auto;
  min-width: 300px;
  max-width: 90%;
  max-width: 90vw;
  height: auto;
  max-height: 90%;
  max-height: 80vh;
}
@media screen and (max-width: 480px) {
  .modal-bg .modal-box #modal-content .media {
    max-width: 100%;
    max-width: 100vw;
    max-height: 100%;
    max-height: 100vh;
  }
}
.modal-bg .modal-box .close-modal {
  position: absolute;
  z-index: 105;
  display: block;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 45px;
  background-color: #a68202;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 400;
}
.modal-bg .modal-box .close-modal:hover {
  cursor: pointer;
}
.modal-bg .modal-box .close-modal span {
  display: inline-block;
  padding: 0 15px;
  margin-right: 15px;
  border-right: 1px #fff solid;
}
.included h2.center  {
  position: relative;
}
.included h2.center::before {
  content: 'Includes Your';
  position: absolute;
  top: -18px;
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
}
.your-journey.included h2.center::before {
  content: '';
}
.event-types.included h2.center::before {
  content: '';
  /* position: absolute;
  top: -18px;
  text-align: center;
  width: 100%;
  font-size: 1.4rem; */
}

.home header ul.nav li a.home,
.event-types header ul.nav li a.dropdown,
.diy-inspiration header ul.nav li a.diy,
.included header ul.nav li a.included,
.faqs header ul.nav li a.faqs,
.pricing header ul.nav li a.pricing,
.rev-calc ul.nav li a.rev-calc,
.vendor-faqs ul.nav li a.vendor-faqs,
.venue-overview ul.nav li .venue-overview,
.try-tac ul.nav li .try-tac,
.get-started ul.nav li .get-started,
.your-journey ul.nav li .your-journey {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  border-bottom: solid 1px #7d7d7d;
}
.home header ul.nav li a.home::after,
.event-types header ul.nav li a.dropdown::after,
.diy-inspiration header ul.nav li a.diy::after,
.included header ul.nav li a.included::after,
.faqs header ul.nav li a.faqs::after,
.pricing header ul.nav li a.pricing::after,
.rev-calc ul.nav li a.rev-calc::after,
.vendor-faqs ul.nav li a.vendor-faqs::after,
.venue-overview ul.nav li .venue-overview::after,
.try-tac ul.nav li .try-tac::after,
.get-started ul.nav li .get-started::after,
.your-journey ul.nav li .your-journey::after {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #7d7d7d;
}
@media screen and (max-width: 800px) {
  .home header ul.nav li a.home,
  .diy-inspiration header ul.nav li a.diy,
  .included header ul.nav li a.included,
  .faqs header ul.nav li a.faqs,
  .pricing header ul.nav li a.pricing,
  .rev-calc ul.nav li a.rev-calc,
  .vendor-faqs ul.nav li a.vendor-faqs,
  .venue-overview ul.nav li .venue-overview,
  .try-tac ul.nav li .try-tac,
  .get-started ul.nav li .get-started,
  .your-journey ul.nav li .your-journey {
    border-bottom: 0;
    border-left: 0px solid #7d7d7d;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .home header ul.nav li a.home::after,
  .diy-inspiration header ul.nav li a.diy::after,
  .included header ul.nav li a.included::after,
  .faqs header ul.nav li a.faqs::after,
  .pricing header ul.nav li a.pricing::after,
  .rev-calc ul.nav li a.rev-calc::after,
  .vendor-faqs ul.nav li a.vendor-faqs::after,
  .venue-overview ul.nav li .venue-overview::after,
  .try-tac ul.nav li .try-tac::after,
  .get-started ul.nav li .get-started::after,
  .your-journey ul.nav li .your-journey::after {
    left: 0;
    bottom: 50%;
    margin-left: 0px;
    margin-bottom: 0px;
    border-top: 0px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 0px solid #7d7d7d;
  }
}
.home #footer-nav .home,
.included #footer-nav .included,
.diy-inspiration #footer-nav .diy,
.faqs #footer-nav .faqs,
.pricing #footer-nav .pricing,
.your-journey #footer-nav .your-journey {
  background-color: #7d7d7d;
}
header {
  width: 100%;
  height: 55px;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
@media screen and (max-width: 800px) {
  header {
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
}
header #mobNav {
  opacity: 0;
  position: absolute;
  top: -100px;
  right: -100px;
  z-index: 999999;
}
@media screen and (max-width: 800px) {
  header #mobNav ~ .nav {
    position: absolute;
    right: -100vw;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
  }
}
header #mobNav:checked ~ .nav {
  right: 0%;
  opacity: 1;
}
header label {
  display: none;
}
@media screen and (max-width: 800px) {
  header label {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    height: 55px;
    width: 55px;
    position: absolute;
    right: 0;
    background-color: #fff;
    z-index: 100;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.3rem;
    color: #505050;
  }
  header label span {
    height: 2px;
    background-color: #959595;
    width: 30px;
    margin: 4px 0;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}
@media screen and (max-width: 480px) {
  header label {
    font-family: 'Montserrat', sans-serif;
    font-size: 0.95rem;
    font-weight: 400;
    line-height: 1.2rem;
  }
  header label::before {
    width: 52%;
    margin: 0 0 4px 0;
  }
  header label::after {
    width: 52%;
    margin: 5px 0 0 0;
  }
}
header #mobNav:checked + label {
  background-color: #F6F6F6;
}
header #mobNav:checked + label span {
  margin: 2px 0;
}
header #mobNav:checked + label span:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 15px;
  margin-right: -17px;
}
header #mobNav:checked + label span:nth-of-type(2) {
  width: 30px;
}
header #mobNav:checked + label span:nth-of-type(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 15px;
  margin-right: -17px;
}
header .brand {
  margin: auto;
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  z-index: 90;
}
@media screen and (max-width: 800px) {
  header .brand {
    width: 100%;
    background: #fff;
  }
}
header .brand a,
header .brand img {
  display: block;
  position: relative;
  height: 70%;
  width: auto;
}
@media screen and (max-width: 800px) {
  header .brand a,
  header .brand img {
    height: 60%;
  }
}
header ul.nav {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .events-description{
    width: 100%;
    padding:20% 10% 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .events-description h2{
    font-family: 'Aleo', serif;
    font-size: 2.4rem;
    font-weight: 300;
    line-height: 4rem;
    color: #505050;
    text-transform: inherit;
  }

  .ddli{
    height: auto !important;
  }
  .dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto !important;
    background-color: #f9f9f9;
    padding: 0;
  }

  .dropdown-content {
    position: relative;
    background-color: #f9f9f9;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    /* padding-top: 15px; */
  }
  .dropdown-content a.dd-link {
    height:60px;
    width: 30%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    
  }
}
@media screen and (min-width: 800px) {
  .events-description{
    width: 100%;
    padding:10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .events-description h2{
    font-family: 'Aleo', serif;
    font-size: 3.4rem;
    font-weight: 300;
    line-height: 5rem;
    color: #505050;
    max-width: 900px;
    text-transform: inherit;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    border-bottom-color: transparent;
  }
  .dropdown:hover {
    border-bottom-color: transparent;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    width: 200px;
    box-shadow: 0px 12px 16px 0px rgba(0,0,0,0.2);
    padding: 4px 0 0 0px;
    z-index: 1;
  }
  .dropdown-content a.dd-link {
    height:50px;
    padding: 0 10px;
  }
  .dropdown-content a.dd-link:hover {
    background-color: #02C3D930;
    /* color: #fff; */
    font-weight:900;
    border-bottom-color: #02C3D9;
  }
  .dropdown:hover .dropdown-content {
    display: block;
    top:50px;
  }
}
@media screen and (max-width: 800px) {
  header ul.nav {
    padding-top: 55px;
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    z-index: 50;
    height: auto;
    box-shadow: 0px 11px 18px 0px rgba(50, 50, 50, 0.39);
    -webkit-transition: all 0.75s;
    -moz-transition: all 0.75s;
    -ms-transition: all 0.75s;
    -o-transition: all 0.75s;
    transition: all 0.75s;
  }
  header ul.nav.mobNavDown {
    -webkit-transition: all 0.75s;
    -moz-transition: all 0.75s;
    -ms-transition: all 0.75s;
    -o-transition: all 0.75s;
    transition: all 0.75s;
  }
}
header ul.nav ul {
  height: 100%;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 800px) {
  header ul.nav ul {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
  }
}
header ul.nav ul li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
  height: 100%;
  position: relative;
  /* overflow: hidden; */
}
@media screen and (max-width: 800px) {
  header ul.nav ul li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    height: 60px;
  }
}
header ul.nav ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 100%;
  color: #7d7d7d;
  text-decoration: none;
  text-align: center;
  width: auto;
  padding: 0 12px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  -webkit-transition: all 0.75s;
  -moz-transition: all 0.75s;
  -ms-transition: all 0.75s;
  -o-transition: all 0.75s;
  transition: all 0.75s;
  border-bottom: solid 2px transparent;
}
header ul.nav ul li a:hover {
  border-bottom: solid 1px #d6d6d6;
}
@media screen and (max-width: 480px) {
  header ul.nav ul li a:hover {
    border-bottom: 0;
  }
}
@media screen and (max-width: 800px) {
  header ul.nav ul li a {
    width: 100%;
    padding: 10px 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
  }
}
header ul.nav ul li.phone {
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 800px) {
  header ul.nav ul li.phone {
    width: 100%;
    border-bottom: solid 1px #d9aa02;
  }
  header ul.nav ul li.phone a::before {
    content: 'call us at';
    display: inline-block;
    padding-right: 8px;
  }
}
header ul.nav ul li.log-in {
  padding: 0 10px 0 0;
}
@media screen and (max-width: 800px) {
  header ul.nav ul li.log-in {
    border-top: solid 1px #d9aa02;
  }
  header ul.nav ul li.log-in a {
    color: #d9aa02;
  }
}
@media screen and (max-height: 800px) and (max-width: 420px) {
  header ul.nav ul li.log-in {
    width: 49.33%;
    padding: 0;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
  }
  header ul.nav ul li.log-in.vend {
    width: 39.33%;
  }
}
header ul.nav ul li.signup {
  position: relative;
  margin-right: 15px;
}
@media screen and (max-height: 800px) and (max-width: 420px) {
  header ul.nav ul li.signup {
    width: 50%;
    padding: 0;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
    margin-right: 0px;
    border-top: solid 1px #d9aa02;
  }
  header ul.nav ul li.signup.vend {
    width: 60%;
  }
}
header ul.nav ul li.signup a {
  border-radius: 25px;
  color: #ffffff;
  background-color: #d9aa02;
  height: 55%;
  padding: 2px 20px 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  border-bottom: none;
  margin: auto;
}
header ul.nav ul li.signup a:hover {
  border-bottom: none;
  background-color: #02C3D9;
}
@media screen and (max-width: 800px) {
  header ul.nav ul li.signup a {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
header ul.nav ul.connect {
  -webkit-box-flex-direction: columns;
  -moz-box-flex-direction: columns;
  -webkit-flex-direction: columns;
  -ms-flex-direction: columns;
  flex-direction: columns;
}
@media screen and (max-height: 800px) and (max-width: 420px) {
  header ul.nav ul.connect {
    -webkit-box-flex-direction: row;
    -moz-box-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex-wrap: nowrap;
    -moz-box-flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
}
@-webkit-keyframes promo-bar-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes promo-bar-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes promo-bar-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes promo-bar-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
body.with-promo-bar header {
  margin-top: 45px;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar header {
    margin-top: 50px;
  }
}
body.with-promo-bar header .bgvid video {
  top: 40px;
}
@keyframes promo-bar-bg {
  0% {
    background-color: #02C3D9;
  }
  50% {
    background-color: #d90263;
  }
  100% {
    background-color: #02C3D9;
  }
}
body.with-promo-bar .promo-bar {
  width: 100%;
  height: 45px;
  background-color: #d9aa02;
  animation: promo-bar-bg 20s linear infinite;
  position: fixed;
  top: 0;
  z-index: 99;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar .promo-bar {
    height: 50px;
  }
}
body.with-promo-bar .promo-bar a {
  width: 100%;
  height: 100%;
  position: absolute;
  color: #fff;
  letter-spacing: 0.025em;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-decoration: none;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar .promo-bar a {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
body.with-promo-bar .promo-bar a .counter-offer-msg {
  display: inline-block;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
}
@media screen and (max-width: 420px) {
  body.with-promo-bar .promo-bar a .counter-offer-msg {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
  }
}
@media screen and (max-width: 350px) {
  body.with-promo-bar .promo-bar a .counter-offer-msg {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
  }
}
body.with-promo-bar .promo-bar a button {
  outline: none;
  border: none;
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #fff;
  color: #d9aa02;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 6px;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar .promo-bar a button {
    margin: 1px auto 3px;
  }
}
body.with-promo-bar .promo-bar .promo-close {
  display: block;
  position: absolute;
  right: 33px;
  top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  z-index: 9999;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar .promo-bar .promo-close {
    right: 10px;
    top: 18px;
  }
}
body.with-promo-bar .promo-bar .discount-bar-link {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 800px) {
  body.with-promo-bar .promo-bar .discount-bar-link {
    flex-direction: column;
  }
}
body.with-promo-bar .promo-bar .discount-bar-link .counter-offer-msg {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 800px) {
  body.with-promo-bar .promo-bar .discount-bar-link .counter-offer-msg {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    letter-spacing: 0.03rem;
  }
}
@media screen and (max-width: 350px) {
  body.with-promo-bar .promo-bar .discount-bar-link .counter-offer-msg {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-weight: 700;
  }
}
body.with-promo-bar .promo-bar .discount-bar-link #endsTime {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.9rem;
  font-weight: 300;
  padding-left: 1rem;
  word-spacing: 0.2rem;
}
@media screen and (max-width: 800px) {
  body.with-promo-bar .promo-bar .discount-bar-link #endsTime {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 0rem;
  }
}
@media screen and (max-width: 350px) {
  body.with-promo-bar .promo-bar .discount-bar-link #endsTime {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.05rem;
    font-weight: 500;
  }
}
@media screen and (max-width: 800px) {
  body.with-promo-bar .promo-bar .additional-bar-msg {
    display: block;
  }
}
body.with-promo-bar .lp-container {
  margin: 160px auto 0;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar .lp-container {
    margin: 130px auto 0;
  }
}
body.with-promo-bar #hero {
  margin-top: 50px;
}
@media screen and (max-width: 480px) {
  body.with-promo-bar #hero {
    margin-top: 60px;
  }
}
@media screen and (max-width: 480px) {
  .nomo {
    display: none !important;
  }
}
h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.6rem;
  font-weight: 300;
  color: #7d7d7d;
}
h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.2rem;
  font-weight: 200;
  text-transform: capitalize;
  margin-top: 0;
}
@media screen and (max-width: 480px) {
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.3rem;
    font-weight: 300;
  }
}
h3 {
  font-family: 'Aleo', serif;
  font-size: 2rem;
  font-weight: 200;
  line-height: 3rem;
}
h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
}
p,
li {
  font-family: 'Aleo', serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.6rem;
}
@media screen and (max-width: 480px) {
  p,
  li {
    line-height: 2.4rem;
  }
}
.top-wave {
  background-image: url(https://tacboard.com/img/t-rev-wave-white.png);
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .top-wave {
    background-image: url(https://tacboard.com/img/t-wave-white.png);
    height: 20px;
    top: -1px;
  }
}
.bottom-wave {
  background-image: url(https://tacboard.com/img/b-wave-white.png);
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .bottom-wave {
    background-image: url(https://tacboard.com/img/b-rev-wave-white.png);
    height: 20px;
    bottom: -1px;
  }
}
.bottom-wave-rev {
  background-image: url(https://tacboard.com/img/b-rev-wave-white.png);
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .bottom-wave-rev {
    background-image: url(https://tacboard.com/img/b-wave-white.png);
    height: 20px;
    bottom: -1px;
  }
}
.bottom-wave-gray {
  background-image: url(https://tacboard.com/img/b-wave-gray.png);
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .bottom-wave-gray {
    height: 20px;
  }
}
.top-wave-gold {
  background-image: url(https://tacboard.com/img/t-wave-gold.png);
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .top-wave-gold {
    height: 20px;
  }
}
.bottom-wave-gold {
  background-image: url(https://tacboard.com/img/b-wave-gold.png);
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .bottom-wave-gold {
    height: 20px;
  }
}
.bottom-wave-cream {
  background-image: url(https://tacboard.com/img/b-wave-cream.png);
  background-size: 100% 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
}
@media screen and (max-width: 480px) {
  .bottom-wave-cream {
    height: 20px;
  }
}
.footnote {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  letter-spacing: 0.05em;
}
.whitebg {
  background-color: #ffffff;
}
.creambg {
  background-color: #f5f5f0;
}
.rosebg {
  background-color: #feddea;
}
.primary-lt-bg {
  background-color: #dbfbff;
}
.primary-bg {
  background-color: #02C3D9 !important;
}
.secondary-bg {
  background-color: #d9aa02 !important;
}
.third-bg {
  background-color: #d90263;
}
.center {
  text-align: center;
}
.theme-primary {
  color: #02C3D9;
}
.theme-secondary {
  color: #d9aa02;
}
.theme-third {
  color: #d90263;
}
.dbl-dk-grey {
  color: #505050;
}
.bg-cream {
  background-color: #f5f5f0;
}
.bg-lt-grey {
  background-color: #ebebeb;
}
.pink-bg {
  background-color: pink;
}
.aqua-bg {
  background-color: #258082;
}
brown-bg {
  background-color: #816857;
}
.cream-bg {
  background-color: #ecddcb;
}
.lav-bg {
  background-color: #c3b0fd;
}
.lt-blue-bg {
  background-color: #a6bdff;
}
.rose-bg {
  background-color: #c72756;
}
.gold-bg {
  background-color: #bebe97;
}
.bold {
  font-weight: 900;
}
.text-center {
  text-align: center;
}
.w100 {
  width: 100%;
}
.maxw80per {
  max-width: 80%;
}
@media screen and (max-width: 480px) {
  .maxw80per {
    max-width: 100%;
    padding-left: 5%;
    padding-right:5%;
  }
  .maxw80per h2 {
    text-align: center;
  }
}
.maxw1350 {
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-25 {
  margin-top: 25px;
}
.padding-top-25 {
  padding-top: 25px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.padding-bottom-25 {
  padding-bottom: 25px;
}
.center {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-wrap: nowrap;
  -moz-box-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.line {
  width: 15%;
  display: inline-block;
  height: 1px;
  background-color: #c2c2c2;
  margin: auto 2%;
}
a.get-tac-now {
  margin: 4px auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #d9aa02;
  color: #fff;
  outline: 0;
  -webkit-transition: all 0.75s;
  -moz-transition: all 0.75s;
  -ms-transition: all 0.75s;
  -o-transition: all 0.75s;
  transition: all 0.75s;
}
a.get-tac-now:hover {
  margin: 0px auto;
  padding: 14px 38px;
  border-bottom: none;
  background-color: #02C3D9;
}
@media screen and (max-width: 480px) {
  a.get-tac-now {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 500;
  }
}
.flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.flex-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.inline-flex-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
.flex-container {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .flex-container {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.flex-justify-center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.flex-justify-start {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}
.flex-align-center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.flex-start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.flex-wrap {
  -webkit-box-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-column {
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.row-reverse {
  -webkit-box-flex-direction: row-reverse;
  -moz-box-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media screen and (max-width: 480px) {
  .row-reverse {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.half {
  width: 50%;
  -webkit-box-flex-grow: 1;
  -moz-box-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
@media screen and (max-width: 480px) {
  .half {
    width: 100%;
  }
}
.third {
  width: 33%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
@media screen and (max-width: 480px) {
  .third {
    width: 100%;
  }
}
.quarter {
  width: 25%;
  -webkit-box-flex-grow: 1;
  -moz-box-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .quarter {
    width: 100%;
  }
}
.eventh {
  -webkit-box-flex-grow: 1;
  -moz-box-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .eventh {
    width: 100%;
  }
}
.thumb-vid {
  width: 90%;
  border-radius: 15px;
}
@keyframes homeVidIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media screen and (max-width: 480px) {
  body.with-promo-bar #home-section {
    margin-top: 55px;
  }
}

#home-section {
  padding: 3% 5%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 82vh; */
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  #home-section {
    margin-top: 40px;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    padding-top: 10%;
    background-image: none !important;
    height: 94vh;
    width: 100%;
    min-height: 590px;
  }
}
@media screen and (max-width: 480px) {
  #home-section {
    padding-top: 15%;
  }
  #home-section.homesec-with-promo {
    padding-top: 25%;
  }
}
#home-section .bgvid {
  position: absolute;
  height: 100%;
  top: 90px;
  right: 0;
  z-index: 0;
  animation: homeVidIn 1s;
}
@media screen and (max-width: 800px) {
  #home-section .bgvid {
    top: inherit;
    bottom: 0;
    left: 0;
  }
}
#home-section .bgvid video {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 0;
}
@media screen and (max-width: 800px) {
  #home-section .bgvid video {
    width: 100%;
    height: auto;
    bottom: 0;
    top: inherit;
  }
}
#home-section .homevid-with-promo video {
  top: 35px;
}
@media screen and (max-width: 800px) {
  #home-section .homevid-with-promo video {
    bottom: 0;
    top: inherit;
  }
}
#home-section .zup {
  z-index: 10;
}
#home-section h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.75rem;
  font-weight: 300;
  color: #7d7d7d;
  letter-spacing: -0.04em;
  margin-bottom: 0;
  max-width: 50%;
}
@media screen and (max-width: 1560px) {
  #home-section h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }
}
@media screen and (max-width: 1440px) {
  #home-section h1 {
    font-size: 3.4rem;
    line-height: 4.3rem;
  }
}
@media screen and (max-width: 1366px) {
  #home-section h1 {
    font-size: 3.25rem;
    line-height: 4.25rem;
  }
}
@media screen and (max-width: 800px) {
  #home-section h1 {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 480px) {
  #home-section h1 {
    font-size: 2.6rem;
    line-height: 3.5rem;
    max-width: 90%;
  }
}
@media screen and (max-width: 350px) {
  #home-section h1 {
    font-size: 2.25rem;
    line-height: 3rem;
  }
}
#home-section h1 span {
  font-family: 'Aleo', serif;
  font-size: 4rem;
  font-weight: 400;
  color: #02C3D9;
  display: block;
  letter-spacing: 1px;
}
@media screen and (max-width: 480px) {
  #home-section h1 span {
    font-size: 2.8rem;
    line-height: 4rem;
    padding-top: 0.75rem;
  }
}
@media screen and (max-width: 350px) {
  #home-section h1 span {
    font-size: 2.6rem;
    line-height: 3.3rem;
  }
}
#home-section p {
  display: block;
  width: 50%;
  font-family: 'Aleo', serif;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 3.2rem;
  color: #505050;
  margin: 20px 0 30px;
}
#home-section p span {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight:900;
  display: block;
  padding-top: 10px;
  text-transform: capitalize;
}
#home-section p span i {
  color: #02C3D9;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
}
@media screen and (max-width: 480px) {
  #home-section p span {
    font-size: inherit;
    font-family: inherit;
  }
}
@media screen and (max-width: 800px) {
  #home-section p {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  #home-section p {
    width: 100%;
    font-size: 1.65rem;
    line-height: 2.6rem;
    margin: 25px 0 20px;
    letter-spacing: -0.015em;
    word-spacing: 0.05em;
  }
}
@media screen and (max-width: 350px) {
  #home-section p {
    font-size: 1.5rem;
    line-height: 2.1rem;
  }
}
#home-section .video {
  height: 60px;
  width: auto;
  border-radius: 30px;
  background-color: #d9aa02;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 5px 5px 5px 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media screen and (max-width: 480px) {
  #home-section .video {
    height: 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 5px 4px 5px 15px;
  }
}
@media screen and (max-width: 350px) {
  #home-section .video {
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 5px 3px 5px 15px;
  }
}
#home-section .video span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: solid 2px #d9aa02;
  margin-left: 10px;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media screen and (max-width: 480px) {
  #home-section .video span {
    height: 40px;
    width: 40px;
  }
}
@media screen and (max-width: 350px) {
  #home-section .video span {
    height: 36px;
    width: 36px;
  }
}
#home-section .video span div {
  width: 0;
  height: 0;
  border-right: 17px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  border-left: 17px solid #d9aa02;
  -webkit-transform: translate(33%);
  -moz-transform: translate(33%);
  -ms-transform: translate(33%);
  transform: translate(33%);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media screen and (max-width: 480px) {
  #home-section .video span div {
    border-right: 13px solid transparent;
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
  }
}
#home-section .video:hover {
  background-color: #fff;
  background-color: #02C3D9;
  color: #fff;
}
#home-section .video:hover span {
  border: solid 2px #02C3D9;
  background-color: #fff;
}
#home-section .video:hover span div {
  border-left: 17px solid #02C3D9;
}

@media only screen and (min-width: 390px) and (max-width:1000px) and (orientation: landscape){
  header .brand {
    left:65%
  }
    #home-section{
        padding:8% 5% 0 !important
    }
    #home-section .bgvid video {
        width: 700px;
    }
    #home-section h1 {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }
    #home-section p {
        width: 45%;
    }
}

@media only screen and (min-width: 810px) and (max-width:1000px){
  header .brand {
    left:65%
  }
    #home-section{
        padding:8% 5% 0 !important
    }
    #home-section .bgvid video {
        width: 700px;
    }
    #home-section h1 {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }
    #home-section p {
        width: 45%;
    }
}

.vendor-bar {
  padding: 4% 0 2%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .vendor-bar {
    padding: 10% 0 5%;
  }
}
.vendor-bar h3 {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.5rem;
  font-weight: 500;
  color: #fff;
}
.vendor-bar h3 span {
  display: inline-block;
  padding: 0 4px;
}
.vendor-bar a.get-tac-now {
  margin: 4px auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  text-decoration: none;
  padding: 10px 30px;
  border-radius: 30px;
  color: #d9aa02;
  background-color: #fff;
  outline: 0;
}
@media screen and (max-width: 480px) {
  .vendor-bar h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 500;
  }
  .vendor-bar h3 span {
    display: block;
  }
  .vendor-bar h3 span:nth-of-type(1) {
    font-family: 'Montserrat', sans-serif;
    font-size: 2.6rem;
    font-weight: 700;
  }
  .vendor-bar h3 span:nth-of-type(2) {
    font-family: 'Aleo', serif;
    font-size: 2rem;
    font-weight: 300;
  }
  .vendor-bar a.get-tac-now {
    font-size: 2rem;
    font-weight: 500;
  }
}
#acknowledgement {
  background-color: #fff;
}
@media screen and (max-width: 1025px) {
  #acknowledgement {
    width: 100vw;
    overflow: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
  }
  #acknowledgement::-webkit-scrollbar {
    display: none;
  }
}
#acknowledgement .container {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  max-width: 1100px;
  margin: auto;
}
@media screen and (max-width: 480px) {
  #acknowledgement .container {
    width: auto;
  }
}
#acknowledgement .container div {
  padding: 40px 20px;
}
@media screen and (max-width: 420px) {
  #acknowledgement .container div {
    padding: 40px 15px;
  }
}
@media screen and (max-width: 350px) {
  #acknowledgement .container div {
    padding: 40px 10px;
  }
}
#acknowledgement .container div img {
  max-width: 115px;
}
#statment {
  background-color: #fff;
  padding: 6% 0;
}
@media screen and (max-width: 480px) {
  #statment {
    padding: 15% 0;
  }
}
#statment .statment-container {
  position: relative;
  width: 80%;
  max-width: 1100px;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 1025px) {
  #statment .statment-container {
    width: 92%;
  }
}
@media screen and (max-width: 480px) {
  #statment .statment-container {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#statment .statment-container .bg-color {
  position: absolute;
  top: 0%;
  left: 0;
  width: 85%;
  height: 100%;
  background-color: #0296a6;
  z-index: 1;
}
@media screen and (max-width: 480px) {
  #statment .statment-container .bg-color {
    width: 100%;
    height: 90%;
  }
}
#statment .statment-container .half {
  z-index: 2;
  position: relative;
}
#statment .statment-container .quote {
  font-family: 'Aleo', serif;
  font-size: 3.2rem;
  font-weight: 300;
  line-height: 4rem;
  font-style: italic;
  padding: 15% 5% 15% 50px;
  color: #fff;
}
@media screen and (max-width: 1025px) {
  #statment .statment-container .quote {
    padding: 7.5%;
    font-family: 'Aleo', serif;
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.8rem;
  }
}
#statment .statment-container .quote span {
  font-style: normal;
}
#statment .statment-container .quote span.quoted-by {
  margin-top: 15px;
  font-family: 'Aleo', serif;
  font-size: 2.2rem;
  font-weight: 700;
  display: block;
  line-height: 3rem;
}
#statment .statment-container .quote span.quoted-location,
#statment .statment-container .quote span.quoted-wedding {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  display: block;
  line-height: 2rem;
}
@media screen and (max-width: 480px) {
  #statment .statment-container .quote span.quoted-location,
  #statment .statment-container .quote span.quoted-wedding {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    display: inline-block;
    padding-right: 8px;
  }
}
#statment .statment-container .img {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 300px;
  margin: 0;
}
@media screen and (max-width: 480px) {
  #statment .statment-container .img {
    height: 250px;
  }
}
#statment .statment-container .img span {
  height: 115%;
}
@media screen and (max-width: 1025px) {
  #statment .statment-container .img span {
    height: 75%;
  }
}
#statment .statment-container .img span img {
  height: 100%;
  border: solid 5px #fff;
  -webkit-box-shadow: 6px 5px 20px 0px rgba(50, 50, 50, 0.25);
  -moz-box-shadow: 8px 5px 20px 0px rgba(50, 50, 50, 0.25);
  box-shadow: 6px 5px 20px 0px rgba(50, 50, 50, 0.25);
}
#statment .statment-container .img span:nth-of-type(odd) img {
  -webkit-transform: rotate(-7deg);
  -moz-transform: rotate(-7deg);
  -ms-transform: rotate(-7deg);
  transform: rotate(-7deg);
  margin: -8% 2% 0 0%;
}
#statment .statment-container .img span:nth-of-type(even) img {
  -webkit-transform: rotate(6deg);
  -moz-transform: rotate(6deg);
  -ms-transform: rotate(6deg);
  transform: rotate(6deg);
  margin: 0% 0 -15% 0%;
}
@media screen and (max-width: 480px) {
  #statment .statment-container .img span {
    height: 95%;
  }
  #statment .statment-container .img span img {
    height: 65vw;
    border: solid 3px #fff;
  }
}
#wpp {
  width: 100%;
  padding: 30px;
  background-color: #c18ba6;
}
@media screen and (max-width: 480px) {
  #wpp {
    padding: 30px 0;
  }
}
#wpp h2 {
  margin: 0;
  color: #fff;
  padding: 0 15px;
}
#wpp p {
  text-align: center;
  width: 90%;
  max-width: 1050px;
  margin: 20px auto;
  display: block;
  color: #fff;
  padding: 0 15px;
}
#wpp audio {
  width: 90%;
  max-width: 700px;
  margin: 10px auto 0;
  display: block;
}
@media screen and (max-width: 480px) {
  #wpp audio {
    width: 96%;
  }
}
#inquiry {
  width: 100%;
  padding: 30px;
}
@media screen and (max-width: 480px) {
  #inquiry {
    padding: 30px 0;
  }
}
#inquiry h2 {
  margin: 0;
  color: #fff;
  padding: 0 15px;
}
#inquiry p {
  text-align: center;
  width: 90%;
  max-width: 1050px;
  margin: 20px auto;
  display: block;
  color: #fff;
  padding: 0 15px;
}
#inquiry audio {
  width: 90%;
  max-width: 700px;
  margin: 10px auto 0;
  display: block;
}
@media screen and (max-width: 480px) {
  #inquiry audio {
    width: 96%;
  }
}
#how-it-works,
#how,
#setup,
#using,
#social-proof,
#perfect-for,
#get-tac,
#venue-inq-bar-check,
#venue-inq-bar {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 5%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 1025px) {
  #how-it-works,
  #how,
  #setup,
  #using,
  #social-proof,
  #perfect-for,
  #get-tac,
  #venue-inq-bar-check,
  #venue-inq-bar {
    padding: 5% 1%;
  }
}
@media screen and (max-width: 480px) {
  #how-it-works,
  #how,
  #setup,
  #using,
  #social-proof,
  #perfect-for,
  #get-tac,
  #venue-inq-bar-check,
  #venue-inq-bar {
    padding: 8% 1%;
  }
}
#how-it-works .advantages,
#how .advantages,
#setup .advantages,
#using .advantages,
#social-proof .advantages,
#perfect-for .advantages,
#get-tac .advantages,
#venue-inq-bar-check .advantages,
#venue-inq-bar .advantages {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
#how-it-works .advantages .advantage,
#how .advantages .advantage,
#setup .advantages .advantage,
#using .advantages .advantage,
#social-proof .advantages .advantage,
#perfect-for .advantages .advantage,
#get-tac .advantages .advantage,
#venue-inq-bar-check .advantages .advantage,
#venue-inq-bar .advantages .advantage {
  border-radius: 10px;
}
@media screen and (max-width: 480px) {
  #how-it-works .advantages .advantage,
  #how .advantages .advantage,
  #setup .advantages .advantage,
  #using .advantages .advantage,
  #social-proof .advantages .advantage,
  #perfect-for .advantages .advantage,
  #get-tac .advantages .advantage,
  #venue-inq-bar-check .advantages .advantage,
  #venue-inq-bar .advantages .advantage {
    border-radius: 0%;
  }
}
#how-it-works .advantages .quarter,
#how .advantages .quarter,
#setup .advantages .quarter,
#using .advantages .quarter,
#social-proof .advantages .quarter,
#perfect-for .advantages .quarter,
#get-tac .advantages .quarter,
#venue-inq-bar-check .advantages .quarter,
#venue-inq-bar .advantages .quarter {
  overflow: hidden;
  max-width: 400px;
}
#how-it-works .advantages .quarter:hover,
#how .advantages .quarter:hover,
#setup .advantages .quarter:hover,
#using .advantages .quarter:hover,
#social-proof .advantages .quarter:hover,
#perfect-for .advantages .quarter:hover,
#get-tac .advantages .quarter:hover,
#venue-inq-bar-check .advantages .quarter:hover,
#venue-inq-bar .advantages .quarter:hover {
  cursor: pointer;
}
#how-it-works .advantages .quarter video,
#how .advantages .quarter video,
#setup .advantages .quarter video,
#using .advantages .quarter video,
#social-proof .advantages .quarter video,
#perfect-for .advantages .quarter video,
#get-tac .advantages .quarter video,
#venue-inq-bar-check .advantages .quarter video,
#venue-inq-bar .advantages .quarter video {
  position: relative;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
}
#how-it-works .advantages .quarter img,
#how .advantages .quarter img,
#setup .advantages .quarter img,
#using .advantages .quarter img,
#social-proof .advantages .quarter img,
#perfect-for .advantages .quarter img,
#get-tac .advantages .quarter img,
#venue-inq-bar-check .advantages .quarter img,
#venue-inq-bar .advantages .quarter img {
  width: 100%;
  height: 100%;
  max-width: 400px;
}
#how-it-works .advantages .quarter span,
#how .advantages .quarter span,
#setup .advantages .quarter span,
#using .advantages .quarter span,
#social-proof .advantages .quarter span,
#perfect-for .advantages .quarter span,
#get-tac .advantages .quarter span,
#venue-inq-bar-check .advantages .quarter span,
#venue-inq-bar .advantages .quarter span {
  position: relative;
}
#how-it-works .advantages .quarter span .vid-btn,
#how .advantages .quarter span .vid-btn,
#setup .advantages .quarter span .vid-btn,
#using .advantages .quarter span .vid-btn,
#social-proof .advantages .quarter span .vid-btn,
#perfect-for .advantages .quarter span .vid-btn,
#get-tac .advantages .quarter span .vid-btn,
#venue-inq-bar-check .advantages .quarter span .vid-btn,
#venue-inq-bar .advantages .quarter span .vid-btn {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #a68202;
  border: #a68202 1px solid;
  padding: 4px 12px;
  border-radius: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
#how-it-works .advantages .quarter:hover .vid-btn,
#how .advantages .quarter:hover .vid-btn,
#setup .advantages .quarter:hover .vid-btn,
#using .advantages .quarter:hover .vid-btn,
#social-proof .advantages .quarter:hover .vid-btn,
#perfect-for .advantages .quarter:hover .vid-btn,
#get-tac .advantages .quarter:hover .vid-btn,
#venue-inq-bar-check .advantages .quarter:hover .vid-btn,
#venue-inq-bar .advantages .quarter:hover .vid-btn {
  background-color: #02C3D9;
  color: #fff;
  border: #02C3D9 1px solid;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  padding: 6px 16px;
  width: 100%;
  top: -16px;
  border-radius: 0px;
}
#how-it-works .advantages .quarter:hover .vid-btn::before,
#how .advantages .quarter:hover .vid-btn::before,
#setup .advantages .quarter:hover .vid-btn::before,
#using .advantages .quarter:hover .vid-btn::before,
#social-proof .advantages .quarter:hover .vid-btn::before,
#perfect-for .advantages .quarter:hover .vid-btn::before,
#get-tac .advantages .quarter:hover .vid-btn::before,
#venue-inq-bar-check .advantages .quarter:hover .vid-btn::before,
#venue-inq-bar .advantages .quarter:hover .vid-btn::before {
  content: 'Click to ';
}
#how-it-works .advantages,
#how .advantages,
#setup .advantages,
#using .advantages,
#social-proof .advantages,
#perfect-for .advantages,
#get-tac .advantages,
#venue-inq-bar-check .advantages,
#venue-inq-bar .advantages,
#how-it-works .steps,
#how .steps,
#setup .steps,
#using .steps,
#social-proof .steps,
#perfect-for .steps,
#get-tac .steps,
#venue-inq-bar-check .steps,
#venue-inq-bar .steps {
  width: 90%;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin: auto;
}
@media screen and (max-width: 800px) {
  #how-it-works .advantages,
  #how .advantages,
  #setup .advantages,
  #using .advantages,
  #social-proof .advantages,
  #perfect-for .advantages,
  #get-tac .advantages,
  #venue-inq-bar-check .advantages,
  #venue-inq-bar .advantages,
  #how-it-works .steps,
  #how .steps,
  #setup .steps,
  #using .steps,
  #social-proof .steps,
  #perfect-for .steps,
  #get-tac .steps,
  #venue-inq-bar-check .steps,
  #venue-inq-bar .steps {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  #how-it-works .advantages,
  #how .advantages,
  #setup .advantages,
  #using .advantages,
  #social-proof .advantages,
  #perfect-for .advantages,
  #get-tac .advantages,
  #venue-inq-bar-check .advantages,
  #venue-inq-bar .advantages,
  #how-it-works .steps,
  #how .steps,
  #setup .steps,
  #using .steps,
  #social-proof .steps,
  #perfect-for .steps,
  #get-tac .steps,
  #venue-inq-bar-check .steps,
  #venue-inq-bar .steps {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
  }
}
#how-it-works .advantages .quarter,
#how .advantages .quarter,
#setup .advantages .quarter,
#using .advantages .quarter,
#social-proof .advantages .quarter,
#perfect-for .advantages .quarter,
#get-tac .advantages .quarter,
#venue-inq-bar-check .advantages .quarter,
#venue-inq-bar .advantages .quarter,
#how-it-works .steps .quarter,
#how .steps .quarter,
#setup .steps .quarter,
#using .steps .quarter,
#social-proof .steps .quarter,
#perfect-for .steps .quarter,
#get-tac .steps .quarter,
#venue-inq-bar-check .steps .quarter,
#venue-inq-bar .steps .quarter {
  position: relative;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  margin: 0 8px;
  padding: 0px 0px 0px;
}
@media screen and (max-width: 480px) {
  #how-it-works .advantages .quarter,
  #how .advantages .quarter,
  #setup .advantages .quarter,
  #using .advantages .quarter,
  #social-proof .advantages .quarter,
  #perfect-for .advantages .quarter,
  #get-tac .advantages .quarter,
  #venue-inq-bar-check .advantages .quarter,
  #venue-inq-bar .advantages .quarter,
  #how-it-works .steps .quarter,
  #how .steps .quarter,
  #setup .steps .quarter,
  #using .steps .quarter,
  #social-proof .steps .quarter,
  #perfect-for .steps .quarter,
  #get-tac .steps .quarter,
  #venue-inq-bar-check .steps .quarter,
  #venue-inq-bar .steps .quarter {
    margin-bottom: 10px;
  }
}
#how-it-works .advantages .quarter span,
#how .advantages .quarter span,
#setup .advantages .quarter span,
#using .advantages .quarter span,
#social-proof .advantages .quarter span,
#perfect-for .advantages .quarter span,
#get-tac .advantages .quarter span,
#venue-inq-bar-check .advantages .quarter span,
#venue-inq-bar .advantages .quarter span,
#how-it-works .steps .quarter span,
#how .steps .quarter span,
#setup .steps .quarter span,
#using .steps .quarter span,
#social-proof .steps .quarter span,
#perfect-for .steps .quarter span,
#get-tac .steps .quarter span,
#venue-inq-bar-check .steps .quarter span,
#venue-inq-bar .steps .quarter span {
  padding: 0 15px 20px;
  background-color: #d6d6d6;
  text-align: center;
}
@media screen and (max-width: 480px) {
  #how-it-works .advantages .quarter span,
  #how .advantages .quarter span,
  #setup .advantages .quarter span,
  #using .advantages .quarter span,
  #social-proof .advantages .quarter span,
  #perfect-for .advantages .quarter span,
  #get-tac .advantages .quarter span,
  #venue-inq-bar-check .advantages .quarter span,
  #venue-inq-bar .advantages .quarter span,
  #how-it-works .steps .quarter span,
  #how .steps .quarter span,
  #setup .steps .quarter span,
  #using .steps .quarter span,
  #social-proof .steps .quarter span,
  #perfect-for .steps .quarter span,
  #get-tac .steps .quarter span,
  #venue-inq-bar-check .steps .quarter span,
  #venue-inq-bar .steps .quarter span {
    background-color: #fff;
  }
}
#how-it-works .advantages .quarter span p,
#how .advantages .quarter span p,
#setup .advantages .quarter span p,
#using .advantages .quarter span p,
#social-proof .advantages .quarter span p,
#perfect-for .advantages .quarter span p,
#get-tac .advantages .quarter span p,
#venue-inq-bar-check .advantages .quarter span p,
#venue-inq-bar .advantages .quarter span p,
#how-it-works .steps .quarter span p,
#how .steps .quarter span p,
#setup .steps .quarter span p,
#using .steps .quarter span p,
#social-proof .steps .quarter span p,
#perfect-for .steps .quarter span p,
#get-tac .steps .quarter span p,
#venue-inq-bar-check .steps .quarter span p,
#venue-inq-bar .steps .quarter span p {
  margin-top: 10px;
}
#venues-should-care {
  position: relative;
  overflow: hidden;
}
@media screen and (min-width: 1024px) {
  #venues-should-care {
    padding: 12% 0;
  }
}
#venues-should-care .bgvid {
  position: absolute;
  bottom: -3.5vh;
  right: 0;
  height: calc(100% + 3.5vh);
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
}
#venues-should-care .bgvid video {
  width: auto;
  height: 100%;
}
#venues-should-care h2 {
  margin-left: 5%;
  position: relative;
  z-index: 10;
}
#venues-should-care .flex-container {
  position: relative;
  z-index: 10;
}
@media screen and (max-width: 800px) {
  #venues-should-care .bgvid {
    height: 80%;
  }
}
@media screen and (max-width: 480px) {
  #venues-should-care {
    height: 117vh;
    max-height: 830px;
  }
  #venues-should-care .bgvid {
    height: auto;
    -webkit-align-items: flex-end;
    -moz-align-items: flex-end;
    -ms-align-items: flex-end;
    align-items: flex-end;
  }
  #venues-should-care .bgvid video {
    width: 150vw;
    height: auto;
  }
}
@media screen and (max-width: 350px) {
  #venues-should-care {
    height: 125vh;
  }
}
span.see-table {
  color: #02C3D9;
  font-weight: 900;
  text-decoration: underline;
  -webkit-text-underline-position: under;
  -ms-text-underline-position: below;
  text-underline-position: under;
  cursor: pointer;
}
.tac-vs-modal {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  height: 0;
  overflow: hidden;
  opacity: 0;
  transition: height 0.1s, opacity 1s;
}
.tac-vs-modal div {
  width: 900px;
  background-color: #fff;
  border-radius: 4px;
  padding: 15px;
  box-shadow: 0 0 25px 5px #000;
}
@media screen and (max-width: 480px) {
  .tac-vs-modal div {
    padding: 15px 5px;
  }
}
.tac-vs-modal div table {
  width: 100%;
  border-spacing: 0px;
}
.tac-vs-modal div table td {
  padding: 10px 0;
}
.tac-vs-modal div table .header {
  font-size: 1.6rem;
  border-bottom: dotted 1px #c2c2c2;
  padding-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .tac-vs-modal div table .header {
    font-size: 1.4rem;
    text-align: left;
    padding-left: 10px;
  }
}
.tac-vs-modal div table .row-title {
  font-size: 1.6rem;
  padding: 0 15px;
  border-right: dotted 1px #c2c2c2;
}
@media screen and (max-width: 480px) {
  .tac-vs-modal div table .row-title {
    font-size: 1.4rem;
    padding: 8px;
  }
}
.tac-vs-modal div table .row-item {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  padding: 15px;
}
@media screen and (max-width: 480px) {
  .tac-vs-modal div table .row-item {
    font-size: 1.3rem;
    padding: 8px;
  }
}
.tac-vs-modal .close-table {
  text-align: center;
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  color: #d90263;
  display: block;
  width: 100px;
  margin: 6px auto 0;
  font-size: 1.2rem;
  padding: 4px 12px;
  border: solid 1px #d90263;
  border-radius: 20px;
  cursor: pointer;
}
.show-table {
  opacity: 1;
  height: 100%;
  height: 100vh;
  transition: height 0.1s, opacity 1s;
}
#how-it-works {
  padding: 5% 0;
}
@media screen and (min-width: 1660px) {
  #how-it-works {
    padding: 5%;
  }
}
@media screen and (max-width: 1025px) {
  #how-it-works .flex-container {
    -webkit-box-flex-wrap: wrap;
    -moz-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
#how-it-works .eventh {
  max-width: 23.5%;
}
@media screen and (max-width: 1025px) {
  #how-it-works .eventh {
    max-width: 49.75%;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 480px) {
  #how-it-works .eventh {
    max-width: 100%;
  }
}
#how-it-works .eventh img {
  width: 250px;
}
#how-it-works .eventh h3 {
  width: 90%;
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: dotted 1px #c2c2c2;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#how-it-works .eventh h3 span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-right: 8px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  background-color: #02C3D9;
}
#how-it-works .eventh p {
  margin-top: 0;
  padding: 0 1rem;
  text-align: center;
  width: 98%;
  margin: auto;
}
#how .advantages span {
  height: 100%;
}
#setup h2 {
  margin-bottom: 20px;
}
#setup h3 {
  margin: 0 auto 35px;
}
@media screen and (max-width: 480px) {
  #setup h3 {
    width: 90%;
  }
}
#setup .steps .quarter {
  padding: 0;
}
#setup .steps .quarter .stpe-vid-box {
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 250px;
  height: 250px;
  border: solid 1px #505050;
  background-color: #fff;
  margin: auto;
  border-radius: 50%;
  margin-bottom: -30px;
  overflow: hidden;
  position: relative;
}
#setup .steps .quarter .stpe-vid-box video {
  width: 100%;
}
@media screen and (max-width: 800px) {
  #setup .steps .quarter .stpe-vid-box {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 480px) {
  #setup .steps .quarter .stpe-vid-box {
    width: 80vw;
    height: 80vw;
  }
}
#setup .steps .quarter span {
  background-color: transparent;
}
#setup .steps .quarter span h4 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 60px;
  height: 60px;
  color: #fff;
  font-weight: 700;
  margin: auto;
  border-radius: 50%;
  position: relative;
  z-index: 5;
}
#setup .steps .quarter:nth-of-type(1) span h4 {
  background-color: #0296a6;
}
#setup .steps .quarter:nth-of-type(2) span h4 {
  background-color: #d9aa02;
}
#setup .steps .quarter:nth-of-type(3) span h4 {
  background-color: #d90263;
}
#using h2 {
  text-transform: none;
}
#using .steps {
  background-position: 30% center, 70% center;
  background-size: 15% auto;
  background-repeat: no-repeat;
}
#using .steps .quarter {
  background-color: transparent;
}
#using .steps .quarter .icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: solid 2px #505050;
  background-color: #fff;
  margin: 0 auto -50px;
  z-index: 2;
}
#using .steps .quarter .icon img {
  max-width: 80px;
  max-height: 80px;
}
#using .steps .quarter span {
  background-color: transparent;
  width: 300px;
  height: 300px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 1025px) {
  #using .steps .quarter span {
    width: 100%;
    height: 30vw;
  }
}
@media screen and (max-width: 480px) {
  #using .steps .quarter span {
    height: 180px;
    padding-top: 35px;
  }
}
#using .steps .quarter span h4 {
  color: #fff;
  font-weight: 700;
  margin: 5px auto 5px;
  padding-bottom: 6px;
  border-bottom: dotted 1px #fff;
  text-align: center;
  width: 80%;
}
#using .steps .quarter span p {
  margin: 4px auto;
  color: #fff;
  font-weight: 400;
  padding-bottom: 10px;
}
#using .steps .quarter:nth-of-type(1) .icon {
  border: solid 2px #0296a6;
}
#using .steps .quarter:nth-of-type(1) span {
  background-color: #0296a6;
}
#using .steps .quarter:nth-of-type(2) .icon {
  border: solid 2px #d9aa02;
}
#using .steps .quarter:nth-of-type(2) .icon img {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
#using .steps .quarter:nth-of-type(2) span {
  background-color: #d9aa02;
}
#using .steps .quarter:nth-of-type(3) .icon {
  border: solid 2px #d90263;
}
#using .steps .quarter:nth-of-type(3) span {
  background-color: #d90263;
}
#perfect-for {
  padding: 8.5% 0;
}
@media screen and (max-width: 800px) {
  #perfect-for {
    padding: 15.5% 0;
  }
}
#perfect-for .perfect-for-container {
  width: 100%;
  max-width: 1100px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#perfect-for .perfect-for-container .eventh img {
  width: 150px;
  margin: auto;
}
@media screen and (max-width: 800px) {
  #perfect-for .perfect-for-container .eventh img {
    max-width: 100px;
  }
}
#perfect-for .perfect-for-container .eventh h3 {
  font-family: 'Aleo', serif;
  font-size: 1.6rem;
  font-weight: 400;
}
#perfect-for .perfect-for-container .eventh:nth-of-type(1) h3 {
  color: #d9aa02;
}
#perfect-for .perfect-for-container .eventh:nth-of-type(2) h3 {
  color: #73baea;
}
#perfect-for .perfect-for-container .eventh:nth-of-type(3) h3 {
  color: #d90263;
}
#perfect-for .perfect-for-container .eventh:nth-of-type(4) h3 {
  color: #02C3D9;
}
#perfect-for .perfect-for-container .eventh:nth-of-type(5) h3 {
  color: #d93f02;
}
#perfect-for .perfect-for-container .eventh:nth-of-type(6) h3 {
  color: #697dd9;
}
@media screen and (max-width: 480px) {
  #perfect-for .perfect-for-container .eventh {
    width: 32%;
    -webkit-box-flex-grow: 0;
    -moz-box-flex-grow: 0;
    -webkit-flex-grow: 0;
    -ms-flex-grow: 0;
    flex-grow: 0;
  }
  #perfect-for .perfect-for-container .eventh img {
    width: 100%;
  }
  #perfect-for .perfect-for-container .eventh h3 {
    margin-top: 0;
    font-family: 'Aleo', serif;
    font-size: 1.4rem;
    font-weight: 400;
  }
}
@media screen and (max-width: 480px) {
  #perfect-for .perfect-for-container {
    -webkit-box-flex-wrap: wrap;
    -moz-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
#social-proof {
  background-color: #c2c2c2;
  position: relative;
  margin-top: 0px;
  padding: 2% 0 10% 0;
}
@media screen and (max-width: 1025px) {
  #social-proof {
    padding: 2% 0 20% 0;
  }
}
#social-proof h2 {
  position: relative;
  z-index: 2;
  color: #fff;
  font-weight: 400;
}
@media screen and (max-width: 1025px) {
  #social-proof h2 {
    margin-top: 50px;
    width: 95%;
  }
}
@media screen and (max-width: 480px) {
  #social-proof h2 {
    font-size: 2.2rem;
  }
}
#social-proof .reviews-container {
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  height: auto;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
#social-proof .reviews-container::-webkit-scrollbar {
  display: none;
}
#social-proof .reviews-container .inner-container {
  width: 200%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 25px 0;
  position: relative;
}
@media screen and (max-width: 1025px) {
  #social-proof .reviews-container .inner-container {
    width: 230vw;
  }
}
@media screen and (max-width: 480px) {
  #social-proof .reviews-container .inner-container {
    width: 520vw;
  }
}
#social-proof .reviews-container .inner-container .review-item {
  max-width: 350px;
  display: inline-block;
  padding: 20px;
  background-color: #fff;
  color: #7d7d7d;
  margin: 0 10px;
  border-radius: 3px;
  position: relative;
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.33);
  -moz-box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.33);
  box-shadow: 3px 3px 12px 0px rgba(50, 50, 50, 0.33);
}
@media screen and (max-width: 1025px) {
  #social-proof .reviews-container .inner-container .review-item {
    width: 35vw;
  }
}
@media screen and (max-width: 480px) {
  #social-proof .reviews-container .inner-container .review-item {
    width: 80vw;
  }
}
#social-proof .reviews-container .inner-container .review-item p {
  margin: 0;
}
#social-proof .reviews-container .inner-container .review-item .review-by-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin-top: 6px;
  color: #7d7d7d;
}
#social-proof .reviews-container .inner-container .review-item .review-avatar {
  overflow: hidden;
  border-radius: 50%;
  max-height: 40px;
  max-width: 40px;
  display: inline-block;
  margin-right: 5px;
}
#social-proof .reviews-container .inner-container .review-item .review-avatar img {
  max-height: 40px;
  max-width: 40px;
}
#social-proof .reviews-container .inner-container .review-item .review-by {
  margin-top: 4px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  display: inline-block;
}
#social-proof .reviews-container .inner-container .review-item .review-source {
  position: absolute;
  right: 20px;
  bottom: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#social-proof .reviews-container .inner-container .review-item .review-source img {
  max-height: 35px;
}
#social-proof .rev-btn-container {
  width: 100%;
  height: 250px;
  top: 50%;
  position: absolute;
  background-color: transparent;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#social-proof .rev-btn-container:hover .rev-btn {
  opacity: 1;
}
@media screen and (max-width: 1025px) {
  #social-proof .rev-btn-container {
    display: none;
  }
}
#social-proof .rev-btn-container .rev-btn {
  height: 120px;
  width: 100px;
  background-color: rgba(0, 0, 0, 0.33);
  position: absolute;
  top: 50%;
  transition: all 0.3s;
  opacity: 0;
  outline: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#social-proof .rev-btn-container .rev-btn:hover {
  background-color: rgba(0, 0, 0, 0.66);
  outline: 0;
}
#social-proof .rev-btn-container #go-left {
  left: 0px;
  -webkit-transform: translate(-25%, -50%);
  -moz-transform: translate(-25%, -50%);
  -ms-transform: translate(-25%, -50%);
  transform: translate(-25%, -50%);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
#social-proof .rev-btn-container #go-right {
  right: 0px;
  -webkit-transform: translate(25%, -50%);
  -moz-transform: translate(25%, -50%);
  -ms-transform: translate(25%, -50%);
  transform: translate(25%, -50%);
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
#social-proof .all-reviews-conteiner {
  width: 100%;
  margin: auto;
  position: absolute;
  bottom: 15%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 40px;
}
@media screen and (max-width: 1025px) {
  #social-proof .all-reviews-conteiner {
    bottom: 5%;
  }
}
#social-proof .all-reviews-conteiner a {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  color: #fff;
  margin: auto 20px;
  text-decoration: none;
  text-align: center;
}
@media screen and (max-width: 1025px) {
  #social-proof .all-reviews-conteiner a {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
    font-weight: 500;
  }
}
@media screen and (max-width: 1025px) {
  #social-proof .all-reviews-conteiner {
    height: 75px;
  }
}
#chevron-arrow-left {
  display: inline-block;
  border-right: 6px solid #fff;
  border-bottom: 6px solid #fff;
  width: 30px;
  height: 30px;
  transform: rotate(-225deg);
  margin-left: 20px;
}
#chevron-arrow-right {
  display: inline-block;
  border-right: 6px solid #fff;
  border-bottom: 6px solid #fff;
  width: 30px;
  height: 30px;
  transform: rotate(-45deg);
  margin-right: 20px;
}
#get-tac h3,
#venue-inq-bar-check h3 {
  width: 95%;
  max-width: 1100px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 500;
  color: #d9aa02;
}
@media screen and (max-width: 800px) {
  #get-tac h3,
  #venue-inq-bar-check h3 {
    width: 85%;
    margin-top: 0px;
  }
}
@media screen and (max-width: 480px) {
  #get-tac h3,
  #venue-inq-bar-check h3 {
    font-family: 'Aleo', serif;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.4rem;
    width: 85%;
  }
}
#get-tac p,
#venue-inq-bar-check p {
  margin: 0 0 50px;
}
@media screen and (max-width: 800px) {
  #get-tac p,
  #venue-inq-bar-check p {
    width: 85%;
  }
}
@media screen and (max-width: 480px) {
  #get-tac p,
  #venue-inq-bar-check p {
    width: 90%;
    text-align: center;
  }
}
#get-tac p.receive,
#venue-inq-bar-check p.receive {
  margin: 0 auto 4px;
  text-align: center;
}
#get-tac a.get-tac-now,
#venue-inq-bar-check a.get-tac-now {
  margin-bottom: 30px;
}
#scop {
  background-color: #7d7d7d;
  padding: 5% 0;
}
#scop p {
  width: 99%;
  max-width: 1100px;
  margin: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 2rem;
  margin-bottom: 6px;
}
#package {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 6% auto;
}
@media screen and (max-width: 480px) {
  #package {
    margin: 20% auto;
  }
}
#package h2 {
  width: 90%;
  max-width: 1000px;
  margin: auto auto 8px;
  text-align: center;
}
#package .package-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: 900px;
  position: relative;
}
#package .package-container h4 {
  width: 100%;
  line-height: 3rem;
  font-family: 'Aleo', serif;
  font-size: 2.5rem;
  font-weight: 300;
  letter-spacing: 0.05rem;
  margin-top: 0;
  color: #d9aa02;
}
@media screen and (max-width: 480px) {
  #package .package-container h4 {
    font-size: 2.2rem;
  }
}
#package .package-container h5 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #505050;
  margin: 14px auto;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#package .package-container .inludes-item-box {
  width: 70%;
  margin: 5px auto 20px;
  border: dotted 1px #c2c2c2;
  background-color: #ebebeb;
  border-radius: 10px;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  #package .package-container .inludes-item-box {
    width: 90%;
  }
}
#package .package-container .section-head {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  border-bottom: dotted 1px #c2c2c2;
  width: 100%;
  padding: 10px 5px;
  margin: 0px auto 5px;
  text-align: center;
  background-color: #d6d6d6;
}
#package .package-container .section-desc {
  font-family: 'Aleo', serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0 auto;
  text-align: center;
  padding: 10px 5px;
}
#package .package-container .footnote {
  margin: 4px auto 9px;
  line-height: 1.2rem;
}
#package .package-container .split-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  width: 90%;
  margin-bottom: 25px;
}
@media screen and (max-width: 480px) {
  #package .package-container .split-container {
    margin-bottom: 0px;
  }
}
#package .package-container .split-container.includes-sec {
  padding: 15px 0;
  border-top: solid 1px #d9aa02;
}
@media screen and (max-width: 480px) {
  #package .package-container .split-container {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#package .package-container .split-container .half {
  max-width: 46%;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  padding: 0 12px;
  position: relative;
}
@media screen and (max-width: 480px) {
  #package .package-container .split-container .half {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
#package .package-container .split-container .half p.section-head {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  border-bottom: dotted 1px #c2c2c2;
  width: 100%;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-align: left;
}
#package .package-container .split-container .half .section-desc {
  text-align: left;
  margin: 0;
  padding-bottom: 15px;
}
#package .package-container p.include-list {
  font-family: 'Aleo', serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.9rem;
  margin: 0 auto;
  width: 90%;
  text-align: left;
  color: #7d7d7d;
  padding: 0 24px;
}
@media screen and (max-width: 480px) {
  #package .package-container p.include-list {
    padding: 0 12px;
  }
}
#package .package-container ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  width: 80%;
}
#package .package-container ul li {
  margin-bottom: 4px;
  line-height: 2.8rem;
}
#package .package-container p.ft-note {
  width: 100%;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}
@media screen and (max-width: 480px) {
  #package .package-container p.ft-note {
    padding: 5px 15px;
    width: 94%;
    font-size: 1.5rem;
  }
}
#package .package-container a {
  margin: 15px auto;
}
#footer-nav {
  width: 100%;
  background-color: #959595;
}
#footer-nav .footer-content-container {
  max-width: 1150px;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 50px 0;
  margin: auto;
  position: relative;
}
@media screen and (max-width: 800px) {
  #footer-nav .footer-content-container {
    width: 90%;
    padding: 20px 0;
  }
}
@media screen and (max-width: 480px) {
  #footer-nav .footer-content-container {
    -webkit-box-flex-wrap: wrap;
    -moz-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
  }
}
#footer-nav .footer-content-container a {
  text-decoration: none;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  border-radius: 30px;
  overflow: hidden;
  padding: 4px 18px;
  box-sizing: border-box;
  transition: 0.5s;
  margin: auto;
}
#footer-nav .footer-content-container a:hover {
  background-color: #7d7d7d;
}
@media screen and (max-width: 800px) {
  #footer-nav .footer-content-container a {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 480px) {
  #footer-nav .footer-content-container a {
    width: 45%;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 300;
    text-align: center;
    padding: 10px 6px;
  }
  #footer-nav .footer-content-container a:nth-last-of-type(1) {
    width: 100%;
    color: #505050;
    background-color: #ebebeb;
    border-radius: 50px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 350px) {
  #footer-nav .footer-content-container a {
    font-size: 1.5rem;
  }
}
#footer-nav .footer-content-container .vline {
  height: 100px;
  width: 1px;
  background-color: #fff;
  display: inline-block;
}
@media screen and (max-width: 480px) {
  #footer-nav .footer-content-container .vline {
    display: none;
  }
}
#footer-contact-us {
  width: 100%;
  border-bottom: dotted 1px #959595;
  background-color: #ebebeb;
}
#footer-contact-us .footer-content-container {
  max-width: 1150px;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
}
@media screen and (max-width: 1025px) {
  #footer-contact-us .footer-content-container {
    width: 90%;
    padding: 20px 0;
  }
}
@media screen and (max-width: 480px) {
  #footer-contact-us .footer-content-container {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#footer-contact-us .footer-content-container .third {
  min-height: auto;
  margin: 50px auto;
}
@media screen and (max-width: 480px) {
  #footer-contact-us .footer-content-container .third {
    width: 100%;
    margin: 10px auto;
  }
}
#footer-contact-us .footer-content-container a {
  text-decoration: none;
}
#footer-contact-us .footer-content-container a:hover .icon {
  transform: rotate(-20deg);
}
#footer-contact-us .footer-content-container a:hover .icon img {
  height: 48px;
  width: 48px;
}
#footer-contact-us .footer-content-container .contact-type-box {
  width: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 480px) {
  #footer-contact-us .footer-content-container .contact-type-box {
    margin: auto;
    width: 80%;
    text-align: left;
  }
}
#footer-contact-us .footer-content-container .contact-type-box .icon {
  transition: all 0.33s;
  height: 40px;
  width: 40px;
}
#footer-contact-us .footer-content-container .contact-type-box .icon img {
  transition: all 0.33s;
  height: 40px;
  width: 40px;
}
@media screen and (max-width: 1025px) {
  #footer-contact-us .footer-content-container .contact-type-box .icon {
    height: 40px;
    width: 40px;
  }
  #footer-contact-us .footer-content-container .contact-type-box .icon img {
    height: 40px;
    width: 40px;
  }
}
@media screen and (max-width: 480px) {
  #footer-contact-us .footer-content-container .contact-type-box .icon {
    height: 35px;
    width: 35px;
  }
  #footer-contact-us .footer-content-container .contact-type-box .icon img {
    height: 35px;
    width: 35px;
  }
}
#footer-contact-us .footer-content-container .contact-type-box .message {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  height: 40px;
}
@media screen and (max-width: 1025px) {
  #footer-contact-us .footer-content-container .contact-type-box .message {
    height: 42px;
  }
}
#footer-contact-us .footer-content-container .contact-type-box .message .top-message {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0px 0 -2px 0;
  color: #959595;
  padding-left: 10px;
}
#footer-contact-us .footer-content-container .contact-type-box .message .big-message {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  margin: -1px 0 0 0;
  color: #959595;
  padding-left: 10px;
}
@media screen and (max-width: 480px) {
  #footer-contact-us .footer-content-container .contact-type-box .message {
    height: 32px;
  }
  #footer-contact-us .footer-content-container .contact-type-box .message .top-message {
    font-size: 1.2rem;
  }
  #footer-contact-us .footer-content-container .contact-type-box .message .big-message {
    font-size: 1.75rem;
  }
}
#footer-links {
  width: 100%;
  min-height: 100px;
  background-color: #ebebeb;
}
#footer-links .footer-content-container {
  max-width: 1000px;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
  -moz-justify-content: space-evenly;
  -ms-justify-content: space-evenly;
  justify-content: space-evenly;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  padding: 50px 0 0;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
}
@media screen and (max-width: 1025px) {
  #footer-links .footer-content-container {
    width: 90%;
  }
}
@media screen and (max-width: 480px) {
  #footer-links .footer-content-container {
    -webkit-box-flex-direction: row;
    -moz-box-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-flex-wrap: wrap;
    -moz-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 50px 0%;
  }
}
#footer-links .footer-content-container .third {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  width: 33%;
  text-align: left;
  max-width: 600px;
  min-height: 33vh;
}
@media screen and (max-width: 480px) {
  #footer-links .footer-content-container .third {
    width: 48%;
  }
  #footer-links .footer-content-container .third:nth-of-type(3) {
    padding-top: 20px;
    border-top: dotted 1px #7d7d7d;
    width: 100%;
  }
}
#footer-links .footer-content-container .third p {
  text-align: left;
  margin-top: 4px;
}
#footer-links .footer-content-container .third a {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0 0 20px 0px;
  color: #7d7d7d;
  text-decoration: none;
  border-radius: 30px;
  overflow: hidden;
  padding: 4px 10px;
  box-sizing: border-box;
  transition: 1s;
  width: auto;
  max-width: 90%;
  text-transform: capitalize;
}
#footer-links .footer-content-container .third a:hover {
  background-color: #F6F6F6;
  padding-left: 40px;
}
@media screen and (max-width: 1025px) {
  #footer-links .footer-content-container .third a {
    font-size: 1.3rem;
    padding: 4px 0px;
    border-radius: 45px;
    width: 100%;
    text-align: left;
    padding-left: 10%;
  }
  #footer-links .footer-content-container .third a.footer-email-link {
    padding-top: 20px;
    font-size: 2.4rem;
  }
}
#footer-links .footer-content-container .third .badge-container {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  #footer-links .footer-content-container .third .badge-container {
    width: 110%;
  }
}
#footer-links .footer-content-container .third .badge-container .badge-box {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-grow: 1;
  -moz-box-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
#footer-links .footer-content-container .third .badge-container .badge-box .stars {
  text-align: center;
  margin: auto;
  font-size: 1.4rem;
  letter-spacing: -0.12em;
  margin-top: -2px;
}
#footer-links .footer-content-container .third .badge-container .badge-box div {
  text-align: center;
  margin: auto;
  font-size: 1.4rem;
  letter-spacing: 0;
  font-family: 'Montserrat', sans-serif;
  color: #7d7d7d;
}
#footer-links .footer-content-container .third .badge-container .badge-box img {
  margin: auto;
}
#footer-links .footer-content-container .third .badge-container .badge-box a {
  font-size: 1.4rem;
  line-height: 0;
  margin: 0 auto;
  color: #7d7d7d;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
  border-radius: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 1s;
  width: auto;
  max-width: 100%;
  text-align: center;
}
#footer-links .footer-content-container .third .badge-container .badge-box a:hover {
  background-color: #F6F6F6;
  padding-left: 0px;
}
#footer-links .footer-content-container .third p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0 0 15px 9px;
  text-transform: capitalize;
  color: #7d7d7d;
}
#footer-links .footer-content-container .third p:nth-of-type(1) {
  padding-bottom: 4px;
  margin: auto;
}
#footer-links .footer-content-container .third p.sitk {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
}
#footer-links .footer-content-container .third p a {
  text-transform: none;
}
@media screen and (max-width: 1025px) {
  #footer-links .footer-content-container .third p {
    font-size: 1.4rem;
    padding: 0 0 11px 0px;
  }
}
#footer-links .footer-content-container .third .foot-form-container {
  margin: 0 0 20px 0px;
  padding: 0 0 0 20px;
  box-sizing: border-box;
  height: auto;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  outline: none;
  position: relative;
}
#footer-links .footer-content-container .third .foot-form-container form {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  outline: none;
}
#footer-links .footer-content-container .third .foot-form-container form input {
  padding: 4px 0px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  outline: none;
  border: none;
  display: inline;
}
@media screen and (max-width: 1025px) {
  #footer-links .footer-content-container .third .foot-form-container form input {
    font-size: 1.4rem;
  }
}
#footer-links .footer-content-container .third .foot-form-container form input[type='text'] {
  width: auto;
  background-color: transparent;
}
#footer-links .footer-content-container .third .foot-form-container form div.button-form {
  width: 24%;
  background-color: #7d7d7d;
  color: #fff;
  border-radius: 0;
  font-size: 1.5rem;
  height: inherit;
  margin: inherit;
  text-align: center;
  padding: 15px 0;
  transition: all 0.5s;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 900;
}
#footer-links .footer-content-container .third .foot-form-container form div.button-form:hover {
  background-color: #d9aa02;
}
#footer-links .footer-content-container .third .foot-form-container form div.button-form input {
  background-color: transparent;
  color: #fff;
  font-weight: 900;
}
#footer-legal {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 50px 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #ebebeb;
}
@media screen and (max-width: 480px) {
  #footer-legal {
    padding: 0 0 50px 0;
  }
}
#footer-legal a {
  font-family: 'Montserrat', sans-serif;
  color: #7d7d7d;
  font-size: 1.2rem;
  text-decoration: none;
}
#hero {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  height: 60vh;
  min-height: 500px;
  background-color: #ebebeb;
  background-size: auto 100%;
  background-position: right bottom;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  #hero {
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10% 10% 15%;
    background-size: auto 40%;
    background-position: right top;
    background-repeat: no-repeat;
  }
}
#hero .cover-shape {
  border-top: 50vw solid rgba(240, 240, 240, 0.9);
  border-right: 53vh solid transparent;
  height: 50vh;
  width: 60vw;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 0;
}
@media screen and (max-width: 480px) {
  #hero .cover-shape {
    background-color: rgba(240, 240, 240, 0.9);
    border: 0;
    border-right: 53vh solid transparent;
    height: 60%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 40%;
  }
}
#hero .content-container {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 480px) {
  #hero .content-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
  }
}
#hero .content-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 5.6rem;
  color: #505050;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}
@media screen and (max-width: 480px) {
  #hero .content-container h1 {
    font-size: 2.9rem;
    line-height: 4rem;
  }
}
@media screen and (max-width: 350px) {
  #hero .content-container h1 {
    font-size: 2.5rem;
    line-height: 3.3rem;
  }
}
#hero .content-container h1 span {
  font-family: 'Aleo', serif;
  font-size: 4rem;
  font-weight: 400;
  color: #02C3D9;
  display: block;
  letter-spacing: 1px;
}
@media screen and (max-width: 480px) {
  #hero .content-container h1 span {
    font-size: 3.1rem;
    line-height: 4rem;
    padding-top: 8px;
  }
}
@media screen and (max-width: 350px) {
  #hero .content-container h1 span {
    font-size: 2.55rem;
    line-height: 3.3rem;
  }
}
#hero .content-container p {
  display: block;
  width: 42.5%;
  font-family: 'Aleo', serif;
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 3.6rem;
  color: #505050;
  margin-bottom: 30px;
}
@media screen and (max-width: 480px) {
  #hero .content-container p {
    width: 100%;
    font-size: 1.9rem;
    line-height: 2.8rem;
    text-align: center;
  }
}
@media screen and (max-width: 350px) {
  #hero .content-container p {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}
.live-photo-stream {
  padding: 8.5% 0;
}
@media screen and (max-width: 480px) {
  .live-photo-stream .flex-container {
    padding: 15% 0;
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.includes {
  width: 100%;
  height: auto;
  padding: 4.5% 0;
}
@media screen and (max-width: 480px) {
  .includes {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15% 0 10%;
  }
  .includes .flex-container {
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.includes .half {
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 90%;
  margin: auto;
}
.includes h2 {
  margin-bottom: 3%;
}
.includes h2:nth-of-type(2) {
  margin-bottom: 1%;
}
.includes .subheadline {
  margin: 0 auto 2.5%;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  color: #7d7d7d;
}
.includes .icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-bottom: 4px;
}
.includes .icon.vendor-icon {
  background-color: #d9aa02;
}
.includes .icon img,
.includes .icon svg {
  height: auto;
  width: auto;
  max-height: 80%;
  max-width: 80%;
  margin: auto;
}
.includes .icon img.content-filter-icon,
.includes .icon svg.content-filter-icon {
  margin-top: 4%;
}
.includes .icon img.sign-icon,
.includes .icon svg.sign-icon {
  margin-top: 6%;
  max-height: 75%;
  max-width: 75%;
}
.includes .icon img.live-stream-icon,
.includes .icon svg.live-stream-icon {
  max-width: 65%;
}
.includes .icon img.online-gallery-icon,
.includes .icon svg.online-gallery-icon {
  max-width: 75%;
}
.includes .icon img.snap-icon,
.includes .icon svg.snap-icon {
  max-width: 95%;
  max-height: 95%;
  height: 90px;
  width: 90px;
}
.includes .icon img.brand-icon,
.includes .icon svg.brand-icon {
  max-width: 95%;
  max-height: 95%;
  height: 90px;
  width: 90px;
}
.includes .icon img.projector-screen-icon,
.includes .icon svg.projector-screen-icon {
  max-width: 95%;
  max-height: 95%;
  height: 90px;
  width: 90px;
}
.includes p,
.includes h4 {
  margin-top: 4px;
  width: 80%;
  max-width: 550px;
}
.includes picture {
  width: 96%;
}
.includes img {
  width: 100%;
  margin: auto;
}
.view-themes-container{
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-themes-btn{
  height:30px;
  width:30px;
  border-radius: 15px;
  border: #959595 solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all .5s;
}
.view-themes-btn:hover{
  background-color: #02C3D950;
  transition: all .5s;
}
.view-theme-text{
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  margin: 0 12px;
}
@media screen and (max-width: 480px) {
  .includes img {
    margin: 10px auto;
  }
  .includes p,
  .includes h4 {
    margin-top: 4px;
    width: 100%;
    max-width: 550px;
  }
}
.includes video {
  width: 80%;
}
@media screen and (max-width: 480px) {
  .includes video {
    margin: 10px auto;
  }
}
.includes h3 {
  text-align: left;
  margin-bottom: 0;
  width: 80%;
  max-width: 550px;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 400;
}
@media screen and (max-width: 480px) {
  .includes h3 {
    text-align: center;
    width: 100%;
  }
}
.includes .eventh {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  position: relative;
}
.includes .eventh h3 {
  text-align: center;
}
.includes .eventh picture {
  width: 60%;
  margin: 6px auto;
}
.includes .eventh img {
  margin: 6px auto;
}
@media screen and (max-width: 480px) {
  .includes .eventh img {
    margin: 10px auto;
  }
}
@media screen and (max-width: 480px) {
  .includes .eventh p {
    padding-bottom: 15px;
    margin-bottom: 0;
    border-bottom: dotted 1px #c2c2c2;
  }
}
#live {
  padding: 8% 0;
}
@media screen and (max-width: 480px) {
  #live {
    padding: 16% 0;
  }
}
.e-cream {
  padding: 8% 0;
}
@media screen and (max-width: 480px) {
  .e-cream {
    padding: 16% 0;
  }
}
/* =========== FAQs  ============= */
/* =========== FAQs  ============= */
.lp-container {
  margin: 100px auto 0;
  width: 100%;
  max-width: 900px;
  position: relative;
}
@media screen and (max-width: 1025px) {
  .lp-container h1 {
    padding: 0 5%;
  }
}
.faq-container p.question {
  color: #505050;
  margin: 5px 0;
  font-weight: 900;
  clear: both;
}
.faq-container p.answer {
  margin: 0px 0 30px 20px;
  clear: both;
}
.faq-container p.answer font {
  font-weight: 900;
}
.faq-container p.multi-answer {
  margin: -15px 0 30px 20px;
}
.faq-container .other-faqs {
  display: block;
  clear: both;
  overflow: hidden;
  width: 100%;
}
.faq-container .topfaqs {
  display: block;
  clear: both;
  overflow: hidden;
  width: 100%;
}
@media screen and (max-width: 1025px) {
  .faq-container .topfaqs {
    padding: 0 5%;
  }
}
.faq-container .topfaqs .video-container {
  margin: 0;
}
@media screen and (max-width: 480px) {
  .faq-container .topfaqs .video-container {
    margin: 1px -13px 2px auto;
  }
}
.faq-container .topfaqs .video-container .video-box {
  width: 180px;
  margin-right: 15px;
  position: relative;
  clear: both;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 20px;
}
.faq-container .topfaqs .video-container .video-box img {
  width: 100%;
}
.faq-container .topfaqs .video-container .video-box .vid-thumb-play {
  position: absolute;
  top: 0;
  left: 0;
}
.faq-container .topfaqs .video-container .video-box p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  margin: 0px auto 0;
}
.faq-container .topfaqs p {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  padding: 12px 22px;
  border-radius: 30px;
  display: inline-block;
  max-width: 75%;
  position: relative;
}
@media screen and (max-width: 480px) {
  .faq-container .topfaqs p {
    max-width: 85%;
  }
}
.faq-container .topfaqs p.question {
  color: #fff;
  margin: 5px 0 5px 17px;
  font-weight: 900;
  background-color: #02C3D9;
}
@media screen and (max-width: 480px) {
  .faq-container .topfaqs p.question {
    margin: 5px 0 5px 5px;
  }
}
.faq-container .topfaqs p.question::after {
  width: 0;
  height: 0;
  border-bottom: 25px solid #02C3D9;
  border-left: 25px solid transparent;
  position: absolute;
  bottom: 10px;
  left: -14px;
  content: '';
}
.faq-container .topfaqs p.answer {
  margin: 0px 17px 36px 20px;
  background-color: #ebebeb;
  float: right;
  text-align: left;
}
@media screen and (max-width: 480px) {
  .faq-container .topfaqs p.answer {
    margin: 0px 5px 36px 20px;
  }
}
.faq-container .topfaqs p.answer::after {
  width: 0;
  height: 0;
  border-bottom: 25px solid #ebebeb;
  border-right: 25px solid transparent;
  position: absolute;
  bottom: 10px;
  right: -14px;
  content: '';
}
.includes.before-your-event {
  padding-bottom: 0;
}
#pricing {
  padding-bottom: 50px;
}
.pricing header .mobile-nav-trigger {
  display: none;
}
.lp-container.pricing-container {
  margin-top: 100px;
}
.date-note {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0.025em;
  padding: 0 0 8px;
  color: #333;
}
@media screen and (max-width: 480px) {
  .pricing-container .flex-container {
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.pricing-container h1 {
  text-align: center;
  margin-bottom: 8px;
}
.pricing-container .discount-h1 {
  font-weight: 900;
  color: #02C3D9;
}
@media screen and (max-width: 480px) {
  .pricing-container .discount-h1 {
    font-size: 2.1rem;
  }
}
.pricing-container .discount-h1 .totalSavings {
  font-size: 2.6rem;
  color: #d9aa02;
  margin-top: 1rem;
  display: block;
}
.pricing-container h3 {
  text-align: center;
  margin-top: 0;
}
@media screen and (max-width: 480px) {
  .pricing-container h3 {
    margin: 0 auto 10px;
    width: 90%;
  }
}
.pricing-container p.ends-in {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 900;
  text-transform: uppercase;
  color: #7d7d7d;
}
.pricing-container p.ends-in .count-down {
  background-color: #d9aa02;
  color: #fff;
  padding: 1rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  display: block;
  line-height: 1.5rem;
  max-width: 400px;
  margin: auto;
  text-transform: capitalize;
}
@media screen and (max-width: 480px) {
  .pricing-container p.ends-in {
    margin-top: 6px;
  }
}
.pricing-container .pricing-box {
  width: 100%;
  max-width: 500px;
  height: auto;
  border: solid 2px #d9aa02;
  background-color: #ebebeb;
  padding: 20px 5px;
  margin: auto;
  text-align: center;
  margin-bottom: 10%;
  position: relative;
  border-radius: 25px;
}
@media screen and (max-width: 480px) {
  .pricing-container .pricing-box {
    max-width: 96%;
  }
}
.pricing-container .pricing-box a.get-tac-now {
  margin: 5% auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 80%;
  padding: 14px 30px;
}
.pricing-container .pricing-box a.get-tac-now:hover {
  padding: 18px 30px;
}
@media screen and (max-width: 480px) {
  .pricing-container .pricing-box a.get-tac-now {
    padding: 14px 20px;
  }
}
.pricing-container .pricing-box p.receive {
  font-family: 'Aleo', serif;
  font-size: 1.8rem;
  font-weight: 400;
  text-align: center;
  border-bottom: dotted 1px #959595;
  margin: 0 auto 5px;
  width: 70%;
  padding-bottom: 8px;
  color: #7d7d7d;
}
.pricing-container ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.pricing-container ul li {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  margin: 1.25rem 0 0.25rem;
  color: #7d7d7d;
}
.pricing-container ul li ul {
  list-style: none;
  list-style-position: unset;
}
.pricing-container ul li ul li {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 0.8rem 0;
}
.pricing-container .percent-savings-br {
  margin: 4% auto 0;
  width: 70%;
  border-top: dotted 1px #959595;
}
.pricing-container .og-price {
  font-family: 'Aleo', serif;
  font-size: 1.9rem;
  font-weight: 400;
  text-align: center;
  color: #959595;
  margin: 1% auto;
  text-decoration: line-through;
}
.pricing-container .was-price {
  font-family: 'Aleo', serif;
  font-size: 2.4rem;
  font-weight: 400;
  text-align: center;
  color: #959595;
  margin: 1% auto -2.5%;
  text-decoration: line-through;
}
.pricing-container .price {
  font-family: 'Aleo', serif;
  font-size: 3.2rem;
  font-weight: 500;
  text-align: center;
  color: #d9aa02;
  margin-top: 5%;
}
.pricing-container .questions {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.9rem;
  color: #959595;
  padding: 6px;
}
.pricing-container .questions a {
  text-decoration: none;
  display: inline-block;
  color: inherit;
  padding-left: 6px;
}
.pricing-container .tight-date {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  color: #7d7d7d;
  width: 65%;
  margin: 0 auto 10px;
  line-height: 1.8rem;
}
.pricing-container .tight-date span {
  color: #d9aa02;
  font-weight: 500;
}
.pricing-container .tight-date a {
  color: #505050;
}
.pricing-container .agree-box {
  width: 75%;
  margin: 4% auto;
  padding: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  position: relative;
  background-color: #F6F6F6;
  border: solid 1px #d9aa02;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}
.pricing-container .agree-box p {
  font-family: 'Aleo', serif;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 0;
  line-height: 2rem;
}
.pricing-container .agree-box span {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  display: block;
  text-align: center;
  width: 100%;
  margin-top: 12px;
}
.pricing-container .agree-box input {
  position: absolute;
  left: -100px;
}
.pricing-container .agree-box input:checked + label {
  background-color: #02C3D9;
}
.pricing-container .agree-box input:checked + label::after {
  opacity: 1;
}
.pricing-container .agree-box label {
  padding: 2px 35px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  background-color: #d9aa02;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
}
.pricing-container .agree-box label::after {
  content: '☑';
  position: absolute;
  right: 2px;
  top: -5px;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2rem;
  font-weight: 300;
  opacity: 0;
}
.pricing-container .discount-form {
  display: none;
  width: 100%;
  position: relative;
}
.pricing-container .discount-form.show-form {
  display: block;
  width: 70%;
  margin: auto;
}
@media screen and (max-width: 480px) {
  .pricing-container .discount-form.show-form {
    width: 100%;
  }
  .pricing-container .discount-form.show-form div {
    width: 90%;
    margin: auto;
  }
}
.pricing-container .discount-form.show-form .count-down {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  display: block;
  line-height: 1.5rem;
}
.pricing-container .discount-form input {
  width: 100%;
  border: 0;
  outline: 0;
  margin-bottom: 15px;
  padding: 14px 0;
  font-size: 1.6rem;
  color: #7d7d7d;
  text-align: center;
  background-color: #F6F6F6;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 28px;
  border: #ddd solid 1px;
  box-shadow: inset 0px 3px 4px #00000020;
}
.pricing-container .discount-form input:focus {
  background-color: #fff;
}
.pricing-container .discount-form textarea {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 6px;
  font-size: 1.3rem;
  color: #7d7d7d;
  margin-bottom: 5px;
}
.pricing-container .discount-form label {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 4px;
  display: block;
}
.pricing-container .discount-form input.button,
.pricing-container .discount-form button {
  background-color: #d9aa02;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 700;
  padding: 14px 20px;
  margin: 6px auto 12px;
  outline: 0;
  border: 0;
  border-radius: 28px;
  width: 100%;
  box-shadow: 0px 3px 2px #00000080;
  transition: all .5s;
}
.pricing-container .discount-form input.button:hover,
.pricing-container .discount-form button:hover {
  box-shadow: 0px 12px 8px #00000030;
  transition: all .5s;
  margin: 6px auto 12px;
}
.want-to-save {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  box-sizing: border-box;
  padding: 25px 20px;
  border: solid 1px #d9aa02;
  margin: 10px auto;
  background-color: #fff;
}
.want-to-save p {
  margin-bottom: 0px !important;
  color: #505050;
}
@media screen and (max-width: 480px) {
  .want-to-save {
    width: 90%;
  }

.pricing-container .discount-form input.button,
.pricing-container .discount-form button{
  width: 90%;
}
}
.want-to-save span {
  font-size: 2.4rem;
  display: block;
  padding-bottom: 6px;
  color: #333;
  text-transform: uppercase;
}
.want-to-save a,
.want-to-save button {
  font-size: 1.45rem;
  font-weight: 500;
  letter-spacing: 0.02em;
  display: block;
  padding: 6px 12px;
  margin-top: 12px;
  text-decoration: none;
  border-radius: 20px;
  color: #fff;
  background-color: #02C3D9;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  outline: none;
  border: none;
}
.contact-us-container {
  margin: 10% auto;
}
.contact-us-container h1 {
  text-align: center;
  margin-bottom: 10px;
}
.contact-us-container h3 {
  text-align: center;
  color: #959595;
  margin: 0 auto 5px;
  padding-bottom: 20px;
}
.contact-us-container .half {
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-align-self: flex-start;
  align-self: flex-start;
  max-width: 600px;
}
.contact-us-container .half p {
  font-family: 'Aleo', serif;
  font-size: 1.5rem;
  font-weight: 500;
  color: #959595;
}
.contact-us-container ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.contact-us-container ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  text-decoration: none;
}
.contact-us-container ul li a .icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 0px;
}
.contact-us-container ul li a .icon img,
.contact-us-container ul li a .icon svg {
  width: 80px;
  height: 80px;
}
.contact-us-container ul li a .icon img.fbm,
.contact-us-container ul li a .icon svg.fbm {
  width: 50px;
  height: 50px;
}
.contact-us-container ul li a .icon img.sms,
.contact-us-container ul li a .icon svg.sms {
  width: 90px;
  height: 90px;
}
.contact-us-container ul li a .contact-by-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.contact-us-container ul li a .contact-by-container p {
  margin: 0;
}
.contact-us-container ul li a .contact-by-container p.contactby {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #959595;
}
.contact-us-container ul li a .contact-by-container p.contactat {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #959595;
}
.contact-us-container .form-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 25px;
  width: 100%;
  background-color: #F6F6F6;
  position: relative;
}
.contact-us-container .form-container #mc_embed_signup {
  width: 100%;
}
.contact-us-container .form-container form {
  width: 100%;
  position: relative;
}
.contact-us-container .form-container form #mc_embed_signup_scroll {
  width: 100%;
}
.contact-us-container .form-container form .mc-field-group {
  width: 100%;
}
.contact-us-container .form-container form .mc-field-group input {
  width: 100%;
  border: 0;
  outline: 0;
  margin-bottom: 15px;
  padding: 6px;
  font-size: 1.3rem;
  color: #7d7d7d;
}
.contact-us-container .form-container form .mc-field-group textarea {
  width: 100%;
  border: 0;
  outline: 0;
  padding: 6px;
  font-size: 1.3rem;
  color: #7d7d7d;
  margin-bottom: 5px;
}
.contact-us-container .form-container form .mc-field-group label {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 4px;
  display: block;
}
.contact-us-container .form-container form input.button {
  background-color: #d9aa02;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 6px 20px;
  margin-bottom: 0;
  outline: none;
  outline: 0;
  border: 0;
}
.divider {
  text-align: center;
  border-bottom: dotted #c2c2c2 1px;
  margin: 0px auto 0;
  width: 90%;
  max-width: 550px;
  display: block;
  height: 1px;
}
/* ============= Pickup 3.0 Pages ============= */
/* ========================================== */
/* ============= Signshop Signs ============= */
/* ============= Signshop Signs ============= */
/* ============= Signshop Signs ============= */
/* ========================================== */
.sign-shop-banner {
  width: 100%;
  height: 525px;
  background-color: #fff;
  background-image: url(https://tacboard.com/img/sign-shop-banner-bg4.jpg);
  background-size: auto 100%;
  background-position: center bottom;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner {
    background-image: none;
    height: 535px;
  }
}
.sign-shop-banner.main-shop-banner {
  background-image: none;
  height: 250px;
}
.sign-shop-banner.tc-shop-banner {
  background-image: none;
  height: 330px;
}
.sign-shop-banner .ss-banner-box {
  width: 100%;
  max-width: 1000px;
  height: 500px;
  box-sizing: border-box;
  padding: 94px 0 20px 35px;
  margin: auto;
  position: relative;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner .ss-banner-box {
    padding: 120px 12px 20px;
    height: 500px;
  }
}
.sign-shop-banner .ss-banner-box.main-shop-banner-box {
  height: auto;
}
.sign-shop-banner .ss-banner-box.main-shop-banner-box p {
  width: 100%;
  font-family: 'Aleo', serif;
  font-size: 2rem;
  font-weight: 200;
  line-height: 3rem;
}
.sign-shop-banner .ss-banner-box.tc-shop-banner-box {
  height: auto;
}
.sign-shop-banner .ss-banner-box.tc-shop-banner-box p {
  width: 100%;
  border-bottom: dotted 2px #c2c2c2;
  padding-bottom: 15px;
}
.sign-shop-banner .ss-banner-box p {
  width: 69%;
  font-family: 'Aleo', serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: #7d7d7d;
  margin-top: 10px;
  text-shadow: 0px 0px 10px #fff;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner .ss-banner-box p {
    width: 100%;
    margin: auto;
    color: #222;
    line-height: 2.6rem;
  }
}
.sign-shop-banner .ss-banner-box h1.lph1 {
  border-bottom: none;
  margin: 0 0 0 0;
  padding-bottom: 0;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner .ss-banner-box h1.lph1 {
    margin-bottom: 8px;
  }
}
.sign-shop-banner .ss-banner-box .video-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  margin: 0;
  width: 69%;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner .ss-banner-box .video-container {
    margin-top: 10px;
  }
}
.sign-shop-banner .ss-banner-box .video-container .video-box {
  height: 112px;
  width: 160px;
  margin-right: 15px;
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}
.sign-shop-banner .ss-banner-box .video-container .video-box img {
  height: 90px;
  width: auto;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner .ss-banner-box .video-container .video-box img {
    height: auto;
    width: 44vw;
  }
}
.sign-shop-banner .ss-banner-box .video-container .video-box .vid-thumb-play {
  position: absolute;
  top: 0;
  left: 0;
}
.sign-shop-banner .ss-banner-box .video-container .video-box p {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  margin: 4px auto 0;
}
.sign-shop-banner .ss-banner-box .sign-shop-banner-pic {
  position: absolute;
  bottom: -10px;
  left: 70%;
  height: 510px;
}
@media screen and (max-width: 480px) {
  .sign-shop-banner .ss-banner-box .sign-shop-banner-pic {
    /* display: none; */
    position: absolute;
    bottom: -60px;
    left: 28%;
    height: 50%;
  }
}
@media screen and (max-width: 350px) {
  .ss-banner-box p {
    line-height: 2.8rem;
  }
}
.sign-shop-buttons-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  max-width: 850px;
  transition: all 0.3s;
}
.sign-nav-fixed {
  top: 10px;
  z-index: 100;
  width: 100%;
  max-width: 800px;
  position: fixed;
  background-color: #fff;
  padding-bottom: 5px;
}
@media screen and (max-width: 850px) {
  .sign-nav-fixed {
    max-width: 668px;
  }
}
@media screen and (max-width: 480px) {
  .sign-nav-fixed {
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    left: 2px;
  }
  .sign-nav-fixed .change-hash,
  .sign-nav-fixed .change-phone {
    color: #d9aa02;
    border-color: #d9aa02;
    background-color: #fff;
    font-size: 1.65rem;
  }
  .sign-nav-fixed .more-signs {
    color: #02C3D9;
    border-color: #02C3D9;
    background-color: #fff;
    font-size: 1.65rem;
  }
}
@media screen and (max-width: 350px) {
  .sign-nav-fixed {
    top: 45px;
  }
}
.change-hash,
.change-phone,
.more-signs {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 8px 12px;
  color: #fff;
  font-family: 'helvetica', sans-serif;
  font-size: 1.6rem;
  border-radius: 20px;
  transition: 0.3s all;
  cursor: pointer;
  text-align: center;
  width: 236px;
}
@media screen and (max-width: 480px) {
  .change-hash,
  .change-phone,
  .more-signs {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 900;
    vertical-align: baseline;
    padding-top: 11px;
    padding: 9px 10px;
    border-radius: 2px;
    height: 50px;
    width: 49% !important;
  }
}
.change-hash,
.change-phone {
  background-color: #d9aa02;
}
.change-hash:hover,
.change-phone:hover {
  background-color: #02C3D9;
}
.more-signs {
  background-color: #02C3D9;
  float: right;
}
.more-signs:hover {
  background-color: #d9aa02;
}
.more-signs-menu {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-color: rgba(141, 206, 202, 0.9);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  display: none;
}
.more-signs-menu .no-num-box {
  height: auto;
  width: 100%;
  max-width: 400px;
  border-radius: 22px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px 20px 50px;
  position: relative;
}
.more-signs-menu .no-num-box h2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  text-align: center;
  color: #7d7d7d;
  margin: 0px 0 4px;
  padding-bottom: 12px;
  text-transform: capitalize;
  border-bottom: dotted 1px #c2c2c2;
}
.more-signs-menu .no-num-box a {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  padding: 10px 0;
  font-family: 'Montserrat', sans-serif;
  color: #7d7d7d;
  text-decoration: none;
  -webkit-transition: all 0.33s;
  -moz-transition: all 0.33s;
  -ms-transition: all 0.33s;
  -o-transition: all 0.33s;
  transition: all 0.33s;
}
@media screen and (max-width: 480px) {
  .more-signs-menu .no-num-box a {
    font-size: 1.8rem;
    padding: 12px 0;
  }
}
.more-signs-menu .no-num-box a::after {
  content: '>';
  display: inline-block;
  padding-left: 8px;
  -webkit-transition: all 0.33s;
  -moz-transition: all 0.33s;
  -ms-transition: all 0.33s;
  -o-transition: all 0.33s;
  transition: all 0.33s;
}
.more-signs-menu .no-num-box a:hover {
  color: #d9aa02;
}
.more-signs-menu .no-num-box a:hover::after {
  padding-left: 12px;
}
.more-signs-menu .no-num-box .close-menu {
  position: absolute;
  font-size: 1.75rem;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -moz-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
  color: #d9aa02;
  font-family: 'Aleo', serif;
  font-weight: 900;
}
.more-signs-menu .no-num-box .close-menu:hover {
  cursor: pointer;
  text-decoration: underline;
}
#content-filter {
  padding: 8% 0 0;
}
@media screen and (max-width: 480px) {
  #content-filter {
    padding: 16% 0 0;
  }
}
#content-filter picture {
  text-align: center;
}
#content-filter img {
  max-height: 420px;
  width: auto;
}
@media screen and (max-width: 480px) {
  #content-filter img {
    width: 76%;
    max-height: inherit;
    margin: 0 auto;
  }
}
#sign-shop-container {
  background-color: #fff;
  padding: 20px 0;
  width: 100%;
  max-width: 800px;
  margin: auto;
}
@media screen and (max-width: 480px) {
  #sign-shop-container {
    background-image: none;
  }
}
#sign-shop-container .welcome-overview {
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
}
@media screen and (max-width: 770px) {
  #sign-shop-container .welcome-overview {
    max-width: 98vw;
  }
}
#sign-shop-container .welcome-overview h3 {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  letter-spacing: 0.03em;
  margin: 0px auto 20px;
  width: 100%;
  text-align: left;
  color: #7d7d7d;
  font-size: 26px;
  font-weight: 100;
  font-family: 'Aleo', serif;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview h3 {
    font-size: 18px;
    -webkit-box-flex-wrap: wrap;
    -moz-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    margin: 0px auto 15px;
  }
}
#sign-shop-container .welcome-overview h3 #forPhone {
  border-bottom: solid 3px #fff;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview h3 #forPhone {
    width: 90vw;
    font-size: 36px;
  }
}
#sign-shop-container .welcome-overview h3 .change-number {
  position: absolute;
  right: 0;
  font-weight: 900;
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  display: inline-block;
  vertical-align: baseline;
  padding-top: 11px;
  padding: 9px 11px;
  color: #fff;
  background-color: #d9aa02;
  transition: all 0.5s;
  border-radius: 20px;
}
#sign-shop-container .welcome-overview h3 .change-number:hover {
  background-color: #02C3D9;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview h3 .change-number {
    left: 0;
    top: 70px;
    text-align: center;
  }
}
#sign-shop-container .welcome-overview h3 .save-new-num {
  box-sizing: border-box;
  width: 90px;
  right: 85px;
  display: none;
  text-align: center;
  padding: 9px 4px;
  color: #fff;
  background-color: #02C3D9;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px;
  margin-right: 1px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
#sign-shop-container .welcome-overview h3 .save-new-num:hover {
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview h3 .save-new-num {
    margin-top: 10px;
    width: 50%;
    margin-right: 0px;
  }
}
#sign-shop-container .welcome-overview h3 .cancel-new-num {
  box-sizing: border-box;
  width: 85px;
  right: 5px;
  display: none;
  text-align: center;
  padding: 9px 4px;
  color: #fff;
  background-color: #d9aa02;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  font-size: 16px;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
#sign-shop-container .welcome-overview h3 .cancel-new-num:hover {
  text-decoration: none;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview h3 .cancel-new-num {
    margin-top: 10px;
    width: 50%;
  }
}
#sign-shop-container .welcome-overview h3 form {
  display: none;
  width: 422px;
  position: relative;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview h3 form {
    width: 100%;
  }
}
#sign-shop-container .welcome-overview h3 form input {
  border: none;
  border-bottom: solid 2px #d9aa02;
  color: #d9aa02;
  font-size: 26px;
  font-weight: 100;
  font-family: 'Aleo', serif;
  padding: 2px 12px 2px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}
#sign-shop-container .welcome-overview h3 form input:focus {
  background-color: #f1fffe;
}
#sign-shop-container .welcome-overview h3 form input:-ms-input-placeholder {
  color: #c2c2c2;
  text-transform: none;
  font-size: 21px;
  font-size: 'Aleo', serif;
}
#sign-shop-container .welcome-overview h3 form input:-moz-placeholder {
  color: #c2c2c2;
  text-transform: none;
  font-size: 21px;
  font-size: 'Aleo', serif;
}
#sign-shop-container .welcome-overview h3 form input::-moz-placeholder {
  color: #c2c2c2;
  text-transform: none;
  font-size: 21px;
  font-size: 'Aleo', serif;
}
#sign-shop-container .welcome-overview h3 form input::-webkit-input-placeholder {
  color: #c2c2c2;
  text-transform: none;
  font-size: 21px;
  font-size: 'Aleo', serif;
}
@media screen and (max-width: 420px) {
  #sign-shop-container .welcome-overview h3 form input:-ms-input-placeholder {
    font-size: 16px;
    color: #959595;
  }
  #sign-shop-container .welcome-overview h3 form input:-moz-placeholder {
    font-size: 16px;
    color: #959595;
  }
  #sign-shop-container .welcome-overview h3 form input::-moz-placeholder {
    font-size: 16px;
    color: #959595;
  }
  #sign-shop-container .welcome-overview h3 form input::-webkit-input-placeholder {
    font-size: 16px;
    color: #959595;
  }
}
#sign-shop-container .welcome-overview h3.tcsec {
  margin: 10px 0 20px;
}
#sign-shop-container .welcome-overview .sign-list {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: nowrap;
  -moz-box-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  margin-bottom: 40px;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview .sign-list {
    display: block;
  }
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod {
  margin-bottom: 30px;
  text-decoration: none;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod div {
  margin-bottom: 5px;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod img {
  width: 100%;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod p {
  text-align: left;
  margin-bottom: 2px;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod p.prod-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.8rem;
  color: #7d7d7d;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod p.prod-price {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #d9aa02;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod p.free-ship {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: #7d7d7d;
}
#sign-shop-container .welcome-overview .sign-list ul li a.open-prod button {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 8px 0;
  padding: 4px 12px 6px;
  border-radius: 20px;
  color: #fff;
  background-color: #d9aa02;
  border: none;
  outline: none;
}
#sign-shop-container .welcome-overview .sign-list-main ul li {
  width: 100%;
  border-top: dotted 1px #7d7d7d;
}
#sign-shop-container .welcome-overview .sign-list-main ul li:nth-of-type(1) {
  margin-top: 35px;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container {
  margin: 40px 0 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container:hover .main-shop-text-container button {
  color: #fff;
  border: solid 1px #fff;
  background-color: #d9aa02;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container:hover .main-shop-text-container button span {
  border: solid 1px #fff;
  background-color: #fff;
  color: #d9aa02;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-text-container {
  width: 65%;
  box-sizing: border-box;
  padding-right: 30px;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-text-container h4,
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-text-container h4 span {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  font-weight: 300;
  margin: 0px 0 10px;
  text-align: left;
  color: #505050;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-text-container p {
  text-align: left;
  font-family: 'Aleo', serif;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.6rem;
  color: #7d7d7d;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-text-container button {
  font-size: 1.6rem;
  padding: 8px 20px;
  margin-left: 0;
  color: #d9aa02;
  border: solid 1px #d9aa02;
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-radius: 32px;
  -webkit-transition: all 0.33s;
  -moz-transition: all 0.33s;
  -ms-transition: all 0.33s;
  -o-transition: all 0.33s;
  transition: all 0.33s;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-text-container button span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 20px;
  height: 20px;
  font-size: 1.2rem;
  line-height: 1.2rem;
  border-radius: 50%;
  font-weight: 900;
  margin-left: 10px;
  border: solid 1px #d9aa02;
  color: #d9aa02;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.main-sign-container .main-shop-img-contianer {
  width: 35%;
  text-align: right;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.open-prod img {
  width: 100%;
}
#sign-shop-container .welcome-overview .sign-list-main ul li a.open-prod .prod-name {
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  font-size: 1.8rem;
}
#sign-shop-container .welcome-overview ul {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 770px) {
  #sign-shop-container .welcome-overview ul {
    max-width: 98vw;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul {
    max-width: 100vw;
  }
}
#sign-shop-container .welcome-overview ul li {
  width: 32%;
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  padding: 0px;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li {
    width: 90%;
    max-width: 90%;
  }
}
#sign-shop-container .welcome-overview ul li .sign-image-container {
  width: 100%;
  height: 320px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  border: solid 1px #c2c2c2;
  transition: all 0.5s;
}
#sign-shop-container .welcome-overview ul li .sign-image-container:hover {
  border: solid 1px #959595;
  -webkit-box-shadow: 0px 0px 22px 0px rgba(50, 50, 50, 0.33);
  -moz-box-shadow: 0px 0px 22px 0px rgba(50, 50, 50, 0.33);
  box-shadow: 0px 0px 22px 0px rgba(50, 50, 50, 0.33);
}
#sign-shop-container .welcome-overview ul li .sign-image-container img {
  transition: all 0.5s;
  height: 238px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container img {
    height: 200px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container img {
    height: 257px;
  }
}
@media screen and (max-width: 400px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container img {
    height: 260px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container img {
    height: 230px;
  }
}
#sign-shop-container .welcome-overview ul li .sign-image-container .mini-number {
  color: #fff;
  position: absolute;
  top: 243px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: 'Lobster', cursive;
  transition: all 0.5s;
  font-size: 32px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number {
    top: 210px;
    font-size: 24px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number {
    top: 275px;
    font-size: 33px;
  }
}
@media screen and (max-width: 400px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number {
    top: 275px;
    font-size: 32px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number {
    top: 242px;
    font-size: 29px;
  }
}
#sign-shop-container .welcome-overview ul li .sign-image-container .mini-number.bach-party-num {
  font-size: 2rem;
  top: 78px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number.bach-party-num {
    font-size: 2rem;
    top: 73px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number.bach-party-num {
    font-size: 2.25rem;
    top: 105px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container .mini-number.bach-party-num {
    font-size: 2rem;
    top: 81px;
  }
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container {
    height: 282px;
    padding-top: 8px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container {
    width: 90%;
    height: 360px;
    padding-top: 12px;
  }
}
@media screen and (max-width: 400px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container {
    width: 100%;
    height: 365px;
    padding-top: 8px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul li .sign-image-container {
    width: 100%;
    height: 320px;
    padding-top: 8px;
  }
}
#sign-shop-container .welcome-overview ul li a.open-sign {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-align: center;
  color: #505050;
  box-sizing: border-box;
  padding: 25px 0px 8px;
  transition: all 0.5s ease;
  text-decoration: none;
  margin-bottom: 1px;
  border-radius: 4px;
  transition: all 0.5s;
}
@media screen and (max-width: 770px) {
  #sign-shop-container .welcome-overview ul li a.open-sign {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li a.open-sign {
    width: 100%;
    margin: auto;
    font-size: 17px;
  }
}
#sign-shop-container .welcome-overview ul li a.open-sign span {
  margin: 6px auto 6px;
  font-size: 16px;
  font-family: 'Aleo', serif;
  color: #959595;
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  align-self: flex-end;
}
#sign-shop-container .welcome-overview ul li .fi-instant-access {
  background-color: #7fa8d7;
}
#sign-shop-container .welcome-overview ul li .fi-extended-access {
  background-color: #84c99e;
}
#sign-shop-container .welcome-overview ul li .fi-extended-access img {
  margin-right: -3px;
  height: 65%;
}
#sign-shop-container .welcome-overview ul li .fi-unlimited-photos {
  background-color: #f4989e;
}
#sign-shop-container .welcome-overview ul li .fi-unlimited-photos img {
  height: 70%;
}
#sign-shop-container .welcome-overview ul li .fi-lasts-forever {
  background-color: #f29bc1;
}
#sign-shop-container .welcome-overview ul li .fi-lasts-forever img {
  height: 75%;
}
#sign-shop-container .welcome-overview ul li .fi-gallery-theme {
  background-color: #bc8ebe;
}
#sign-shop-container .welcome-overview ul li .fi-photo-download {
  background-color: #7dccc7;
}
#sign-shop-container .welcome-overview ul li .fi-sign-shop {
  background-color: #72d0f4;
}
#sign-shop-container .welcome-overview ul li .fi-sign-shop img {
  height: 70%;
}
#sign-shop-container .welcome-overview ul li .fi-content-screening {
  background-color: #fcc58e;
}
#sign-shop-container .welcome-overview ul li .fi-content-screening img {
  margin-top: 7px;
}
#sign-shop-container .welcome-overview ul li .fi-upload {
  background-color: #84c99e;
}
#sign-shop-container .welcome-overview ul li .fi-upload img {
  margin-top: 14px;
}
#sign-shop-container .welcome-overview ul li .fi-just-married {
  background-color: #00aeef;
}
#sign-shop-container .welcome-overview ul li .fi-just-married img {
  margin-top: 14px;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul li .fi-content-screening {
    background-color: #fcc58e;
  }
  #sign-shop-container .welcome-overview ul li .fi-content-screening img {
    margin-top: 3px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container {
  list-style: none;
  padding: 0;
  position: relative;
  width: 100%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 770px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container {
    max-width: 98vw;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container {
    max-width: 100vw;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li {
  width: 49%;
  position: relative;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  padding: 0px;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li {
    width: 90%;
    max-width: 90%;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container {
  width: 100%;
  height: 290px;
  overflow: hidden;
  background-color: #fff;
  position: relative;
  box-sizing: border-box;
  border: solid 1px #c2c2c2;
  transition: all 0.5s;
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container img {
  transition: all 0.5s;
  height: 130%;
  width: auto;
  margin-top: -15%;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container img {
    height: 120%;
    margin-top: -13%;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container img {
    height: 278px;
    width: auto;
    margin-top: -34px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container img {
    height: 238px;
    margin-top: -14px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number {
  color: #fff;
  position: absolute;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: 'Copperplate Gothic LT W01_31BC', sans-serif;
  transition: all 0.55s;
  font-size: 30px;
  -ms-transform: skewX(-13deg) translateX(-56.5%);
  -webkit-transform: skewX(-13deg) translateX(-56.5%);
  transform: skewX(-13deg) translateX(-56.5%);
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc01 {
  top: 156px;
  font-size: 33px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc01 {
    top: 55%;
    font-size: 28px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc01 {
    top: 53%;
    font-size: 26px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc01 {
    top: 50%;
    font-size: 21px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc02 {
  top: 173px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc02 {
    top: 60.5%;
    font-size: 28px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc02 {
    top: 58%;
    font-size: 26px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc02 {
    top: 54.5%;
    font-size: 21px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc03 {
  top: 175px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc03 {
    top: 60%;
    font-size: 26px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc03 {
    top: 58%;
    font-size: 22px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc03 {
    top: 54%;
    font-size: 18px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc04 {
  top: 169px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc04 {
    top: 59.5%;
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc04 {
    top: 57%;
    font-size: 25px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc04 {
    top: 53.5%;
    font-size: 21px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc05 {
  top: 182px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc05 {
    top: 59.5%;
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc05 {
    top: 60%;
    font-size: 25px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc05 {
    top: 53.5%;
    font-size: 21px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc06 {
  top: 172px;
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc06 {
    top: 59.5%;
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc06 {
    top: 57%;
    font-size: 25px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number.tc06 {
    top: 53.5%;
    font-size: 21px;
  }
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number {
    top: 212px;
    font-size: 24px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number {
    top: 275px;
    font-size: 32px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container .mini-number {
    top: 235px;
  }
}
@media screen and (max-width: 800px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container {
    height: 282px;
    padding-top: 8px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container {
    height: 260px;
    padding-top: 12px;
  }
}
@media screen and (max-width: 350px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .sign-image-container {
    height: 260px;
    padding-top: 8px;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li a.open-sign {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-align: center;
  color: #505050;
  box-sizing: border-box;
  padding: 25px 0px 8px;
  transition: all 0.5s ease;
  text-decoration: none;
  margin-bottom: 1px;
  border-radius: 4px;
  border: solid 1px #fff;
}
@media screen and (min-width: 1050px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li a.open-sign {
    /* &:hover {
                           background-color: @theme-primary;
                           padding: 20px 15px 13px;
                           .sign-image-container {
                               height: 270px;
                               img{
                                   height: 346px;
                               }
                               .mini-number {
                                   top: 211px;
                                   font-size: 28px;
                                   &.tc01{
                                       top: 142px;
                                   }
                                   &.tc02{
                                       top: 160px;
                                   }
                                   &.tc03{
                                       top: 162px;
                                   }
                                   &.tc04{
                                       top: 157px;
                                   }
                               }
                           }
                           span {
                               color: #fff;
                           }
                       }*/
  }
}
@media screen and (max-width: 770px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li a.open-sign {
    font-size: 18px;
  }
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li a.open-sign {
    width: 100%;
    margin: auto;
    font-size: 17px;
  }
  #sign-shop-container .welcome-overview ul.table-card-list-container li a.open-sign:hover {
    background-color: #fff;
  }
}
#sign-shop-container .welcome-overview ul.table-card-list-container li a.open-sign span {
  margin: 6px auto 6px;
  font-size: 16px;
  font-family: 'Aleo', serif;
  color: #959595;
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  align-self: flex-end;
}
@media screen and (max-width: 480px) {
  #sign-shop-container .welcome-overview ul.table-card-list-container li .fi-content-screening {
    background-color: #fcc58e;
  }
  #sign-shop-container .welcome-overview ul.table-card-list-container li .fi-content-screening img {
    margin-top: 3px;
  }
}
.prod-features-show {
  background-color: #fff !important;
}
/* ==================================================== */
/* ============= Automated Signshop Signs ============= */
/* ==================================================== */
.sign-shop-sign {
  background-color: #ccc;
  text-align: center;
  padding: 0;
  margin: 0;
}
.sign-shop-sign .content-box {
  width: 100%;
  margin: auto;
  padding: 0px;
  text-align: left;
  border-radius: 6px;
  box-sizing: border-box;
  display: none;
}
.sign-shop-sign .content-boxD {
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: #8dceca;
  background-image: -o-linear-gradient(bottom, #8dceca 0%, #a6bdff 100%);
  background-image: -moz-linear-gradient(bottom, #8dceca 0%, #a6bdff 100%);
  background-image: -webkit-linear-gradient(bottom, #8dceca 0%, #a6bdff 100%);
  background-image: -ms-linear-gradient(bottom, #8dceca 0%, #a6bdff 100%);
  background-image: linear-gradient(to bottom, #8dceca 0%, #a6bdff 100%);
  margin: 0;
  padding: 90px 0 0 0;
  box-sizing: border-box;
  text-align: center;
}
.sign-shop-sign #Displaylife {
  text-align: center;
  background-color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 40px;
  width: 408px;
  height: 528px;
  -webkit-box-shadow: 0px 5px 13px 0px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 0px 5px 13px 0px rgba(50, 50, 50, 0.5);
  box-shadow: 0px 5px 13px 0px rgba(50, 50, 50, 0.5);
}
@media screen and (max-width: 420px) {
  .sign-shop-sign #Displaylife {
    zoom: 0.9;
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    /* IE 9 */
    -webkit-transform: scale(0.9);
    /* Safari */
    transform: scale(1, 1.1);
    margin-top: 25%;
  }
}
@media screen and (max-width: 360px) {
  .sign-shop-sign #Displaylife {
    zoom: 0.75;
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    /* IE 9 */
    -webkit-transform: scale(0.75);
    /* Safari */
    transform: scale(2, 3);
  }
}
.sign-shop-sign #PDFlife {
  text-align: center;
  background-color: #fff;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  margin-top: 0px;
  width: 100vw;
  height: auto;
}
.sign-shop-sign .applicationPDF {
  font-family: monospace;
  padding: 28px 20px 32px;
  position: relative;
  width: 100%;
  margin: auto;
  margin-left: -13px;
  margin-top: -20px;
  color: white;
  background-image: url(https://tacboard.com/img/ctl-bg-big.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  text-align: center;
  display: block;
}
.sign-shop-sign #Displaylife .applicationPDF {
  padding: 10px 10px;
  width: 75%;
  margin: auto;
  color: white;
  background-image: url(https://tacboard.com/img/ctl-bg-big.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  display: block;
}
.sign-shop-sign #Displaylife .main-message {
  font-size: 120px;
  line-height: 120px;
  padding-top: 60px;
}
.sign-shop-sign p.tpt {
  font-size: 72px;
  font-family: 'Wire One', sans-serif;
  margin-bottom: 0px;
}
.sign-shop-sign #Displaylife p.tpt {
  font-size: 50px;
  margin-top: 30px;
  margin-bottom: 5px;
}
.sign-shop-sign .applicationPDF span {
  display: inline-block;
}
.sign-shop-sign .tacboardPDF {
  font-size: 85px;
  letter-spacing: 2px;
  font-family: 'Lobster', cursive;
}
.sign-shop-sign #Displaylife .tacboardPDF {
  font-size: 42px;
  letter-spacing: 2px;
  font-family: 'Lobster', cursive;
}
.sign-shop-sign #buttons {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 102px;
  width: 100vw;
  position: fixed;
  z-index: 100;
  top: 0px;
  left: 0px;
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-bottom: #efefef solid 1px;
}
.sign-shop-sign #buttons .close-feature {
  position: fixed;
  top: 25px;
  right: 18px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-align: center;
  background-color: #fb9590;
  font-family: 'Helvetica Neue LT W01_65 Md';
  outline: none;
  border: none;
  color: #fff;
  font-size: 20px;
  width: 100px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px 12px;
  text-transform: capitalize;
  text-decoration: none;
}
.sign-shop-sign #buttons .close-feature:hover {
  cursor: pointer;
}
.sign-shop-sign #buttons button {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  text-align: center;
  background-color: #8dceca;
  padding: 5px 15px;
  outline: none;
  border: none;
  color: #fff;
  font-family: 'Helvetica Neue LT W01_65 Md';
  font-size: 20px;
  width: 250px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;
}
@media screen and (max-width: 420px) {
  .sign-shop-sign #buttons {
    box-sizing: border-box;
    padding-left: 10px;
    -webkit-align-items: flex-start;
    -moz-align-items: flex-start;
    -ms-align-items: flex-start;
    align-items: flex-start;
  }
  .sign-shop-sign #buttons button {
    width: 205px;
    font-size: 18px;
    margin: 0;
  }
  .sign-shop-sign #buttons .close-feature {
    right: 10px;
    width: 85px;
    font-size: 18px;
  }
}
.sign-shop-sign #Displaylife .applicationPDF {
    background-image: none;
    padding-top: 0px;
    width:90%;
}

.sign-shop-sign .applicationPDF {
    background-image: none;
    padding-top: 0px;
    width:100%;
    margin-left: -20px;
    margin-top:-10px;
    text-align: center;
}

.sign-shop-sign #Displaylife .tacboardPDF {
    font-family: 'Oswald', sans-serif;
    color: #000;
    font-size: 46px;
    line-height: 45px;
}

.sign-shop-sign #PDFlife .tacboardPDF {
    font-family: 'Oswald', sans-serif;
    color: #000;
    font-size: 100px;
    line-height: 110px;
}
/* =================== SHOP TABLE CARD PAGE ==================== */
/* =================== SHOP TABLE CARD PAGE ==================== */
/* =================== SHOP TABLE CARD PAGE ==================== */
body.table-card-shop .navbar {
  list-style: none;
}
body.table-card-shop a.close-feature {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  color: #d9aa02;
  text-decoration: none;
  padding: 6px 12px;
  border: solid 1px #d9aa02;
  border-radius: 20px;
}
body.table-card-shop .tc-main-container {
  width: 100%;
  max-width: 1000px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 80px auto;
  box-sizing: border-box;
  padding: 20px 25px 20px 35px;
  background-color: #fff;
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container {
    margin: 30px auto;
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 15px 15px 15px 15px;
  }
}
body.table-card-shop .tc-main-container .tc-info-box {
  width: 45%;
  position: relative;
  box-sizing: border-box;
  padding-right: 20px;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-info-box {
    width: 50%;
    padding-right: 10px;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-info-box {
    width: 100%;
    padding-right: 0px;
  }
}
body.table-card-shop .tc-main-container .tc-info-box h1 {
  margin-bottom: 20px;
  font-size: 3.6rem;
  letter-spacing: -0.05em;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-info-box h1 {
    font-size: 3.35rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-info-box h1 {
    font-size: 3.15rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-info-box h1 {
    font-size: 2.75rem;
  }
}
body.table-card-shop .tc-main-container .tc-info-box h2 {
  margin-bottom: 20px;
  font-size: 2.4rem;
  color: #505050;
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-info-box h2 {
    font-size: 2.45rem;
  }
}
body.table-card-shop .tc-main-container .tc-info-box p {
  color: #505050;
  font-family: 'Aleo', serif;
  font-size: 1.6rem;
  font-weight: 300;
}
body.table-card-shop .tc-main-container .tc-info-box p.footnote,
body.table-card-shop .tc-main-container .tc-info-box p.footnote a {
  margin-top: 32px;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
body.table-card-shop .tc-main-container .tc-info-box p.footnote a,
body.table-card-shop .tc-main-container .tc-info-box p.footnote a a {
  color: #d9aa02;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  text-decoration: none;
}
body.table-card-shop .tc-main-container .tc-info-box li {
  color: #505050;
  font-family: 'Aleo', serif;
  font-size: 1.6rem;
  font-weight: 300;
  margin-bottom: 0.8rem;
}
body.table-card-shop .tc-main-container .tc-info-box ul {
  margin-left: 15px;
}
body.table-card-shop .tc-main-container .tc-info-box .buy-btn {
  display: inline-block;
  position: relative;
}
body.table-card-shop .tc-main-container .tc-info-box .buy-btn .stripe-button-el span::after {
  display: block;
  font-size: 15px;
  line-height: 17px;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-info-box .buy-btn .stripe-button-el span::after {
    font-size: 15px;
    line-height: 14px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-info-box .buy-btn .stripe-button-el span::after {
    font-size: 13px;
    line-height: 11px;
  }
}
body.table-card-shop .tc-main-container .tc-info-box .buy-btn#button1 .stripe-button-el span::after {
  content: '($25)';
}
body.table-card-shop .tc-main-container .tc-info-box .buy-btn#button2 .stripe-button-el span::after {
  content: '($40)';
}
body.table-card-shop .tc-main-container .tc-info-box .buy-btn#button3 .stripe-button-el span::after {
  content: '($70)';
}
body.table-card-shop .tc-main-container .tc-info-box .buy-btn .num-check-trigger {
  width: 100%;
  height: 65%;
  position: absolute;
  top: 35%;
  left: 0;
  background-color: transparent;
  cursor: pointer;
}
body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el {
  min-width: 90px;
  background-image: none !important;
  background-color: #d9aa02 !important;
  padding: 10px 8px;
  border-radius: 6px;
  margin-top: 40px;
  transition: all 0.5s;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el {
    padding: 10px 5px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el {
    padding: 5px 0px;
  }
}
body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el:hover {
  background-color: #02C3D9 !important;
  transition: all 0.5s;
}
body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el span {
  background-image: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
  text-shadow: none !important;
  font-size: 19px !important;
  font-weight: 900 !important;
  font-family: 'Montserrat', sans-serif !important;
  min-height: 50px !important;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el span {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-info-box .stripe-button-el span {
    font-size: 15px !important;
    letter-spacing: 0.025em;
  }
}
body.table-card-shop .tc-main-container .tc-image-box {
  width: 55%;
  position: relative;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box {
    width: 100%;
  }
}
body.table-card-shop .tc-main-container .tc-image-box .tc-content {
  margin: auto;
  display: block;
  position: relative;
  text-align: center;
}
body.table-card-shop .tc-main-container .tc-image-box .mySlides {
  display: none;
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: relative;
  overflow: hidden;
}
body.table-card-shop .tc-main-container .tc-image-box .mySlides .loadPhoneNumber,
body.table-card-shop .tc-main-container .tc-image-box .tc-col .loadPhoneNumber {
  position: absolute;
  z-index: 3;
}
body.table-card-shop .tc-main-container .tc-image-box .mySlides .phoneNumberA,
body.table-card-shop .tc-main-container .tc-image-box .tc-col .phoneNumberA {
  -ms-transform: skew(-11deg);
  /* IE 9 */
  -webkit-transform: skew(-11deg);
  /* Safari */
  transform: skew(-11deg);
  /* Standard syntax */
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .loadPhoneNumber {
  font-family: 'Copperplate Gothic LT W01_31BC', sans-serif;
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberA {
  width: 75%;
  top: 57%;
  left: 5%;
  font-size: 4.2rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberA {
    font-size: 3.35rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberA {
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberA {
    font-size: 2.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberA {
  width: 75%;
  top: 57%;
  left: 5%;
  font-size: 1.35rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberA {
    font-size: 0.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberB {
  width: 81.5%;
  top: 61%;
  left: 3.5%;
  font-size: 4.5rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberB {
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberB {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberB {
    font-size: 2.9rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberB {
  width: 81.5%;
  top: 61%;
  left: 3.5%;
  font-size: 1.45rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberB {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberB {
    font-size: 1.05rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberB {
    font-size: 0.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberC {
  width: 55.5%;
  top: 64%;
  right: 9.5%;
  font-size: 3.05rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberC {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberC {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .mySlides .phoneNumberC {
    font-size: 2rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberC {
  width: 55.5%;
  top: 64%;
  right: 9.5%;
  font-size: 0.9rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberC {
    font-size: 7px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc1 .tc-col .phoneNumberC {
    font-size: 6px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .loadPhoneNumber {
  font-family: 'Copperplate Gothic LT W01_31BC', sans-serif;
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberA {
  width: 75%;
  top: 61.5%;
  left: 5%;
  font-size: 4.2rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberA {
    font-size: 3.35rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberA {
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberA {
    font-size: 2.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberA {
  width: 75%;
  top: 61.5%;
  left: 5%;
  font-size: 1.35rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberA {
    font-size: 0.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberB {
  width: 81.5%;
  top: 65.5%;
  left: 3.5%;
  font-size: 4.5rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberB {
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberB {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberB {
    font-size: 2.9rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberB {
  width: 81.5%;
  top: 65.5%;
  left: 3.5%;
  font-size: 1.45rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberB {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberB {
    font-size: 1.05rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberB {
    font-size: 0.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberC {
  width: 55.5%;
  top: 67.5%;
  right: 9.5%;
  font-size: 3.05rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberC {
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberC {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .mySlides .phoneNumberC {
    font-size: 2rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberC {
  width: 55.5%;
  top: 67.5%;
  right: 9.5%;
  font-size: 0.9rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberC {
    font-size: 7px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc2 .tc-col .phoneNumberC {
    font-size: 6px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .loadPhoneNumber {
  font-family: 'American Typewriter ITC W01 Bd', sans-serif;
  font-weight: 100;
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberA {
  width: 75%;
  top: 61.5%;
  left: 5%;
  font-size: 3.5rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberA {
    font-size: 2.8rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberA {
    font-size: 2.9rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberA {
    font-size: 2.45rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberA {
  width: 75%;
  top: 61.5%;
  left: 5%;
  font-size: 1.15rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberA {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberA {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberA {
    font-size: 7px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberB {
  width: 81.5%;
  top: 65%;
  left: 3.5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberB {
    font-size: 3.15rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberB {
    font-size: 3.15rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberB {
    font-size: 2.55rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberB {
  width: 81.5%;
  top: 65%;
  left: 3.5%;
  font-size: 1.2rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberB {
    font-size: 0.85rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberB {
    font-size: 0.95rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberB {
    font-size: 8px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberC {
  width: 55.5%;
  top: 67%;
  right: 9.5%;
  font-size: 2.7rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberC {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberC {
    font-size: 2.15rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .mySlides .phoneNumberC {
    font-size: 1.8rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberC {
  width: 55.5%;
  top: 67.5%;
  right: 9.5%;
  font-size: 0.85rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberC {
    font-size: 6px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc3 .tc-col .phoneNumberC {
    font-size: 5px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .loadPhoneNumber {
  font-family: 'Croissant One', serif;
  font-weight: 100;
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberA {
  width: 75%;
  top: 59%;
  left: 5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberA {
    font-size: 3.15rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberA {
    font-size: 3.1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberA {
    font-size: 2.6rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberA {
  width: 75%;
  top: 59%;
  left: 5%;
  font-size: 1.275rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberA {
    font-size: 8px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberB {
  width: 81.5%;
  top: 63%;
  left: 3.5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberB {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberB {
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberB {
    font-size: 2.7rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberB {
  width: 81.5%;
  top: 63%;
  left: 3.5%;
  font-size: 1.2rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberB {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberB {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberB {
    font-size: 9px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberC {
  width: 55.5%;
  top: 65.5%;
  right: 9.5%;
  font-size: 2.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberC {
    font-size: 2.35rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberC {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .mySlides .phoneNumberC {
    font-size: 1.9rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberC {
  width: 55.5%;
  top: 65.5%;
  right: 9.5%;
  font-size: 0.9rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberC {
    font-size: 7px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc4 .tc-col .phoneNumberC {
    font-size: 6px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .loadPhoneNumber {
  font-family: 'Copperplate Gothic LT W01_31BC', sans-serif;
  font-weight: 100;
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberA {
  width: 75%;
  top: 64%;
  left: 5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberA {
    font-size: 3.15rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberA {
    font-size: 3.1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberA {
    font-size: 2.6rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberA {
  width: 75%;
  top: 64%;
  left: 5%;
  font-size: 1.275rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberA {
    font-size: 8px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberB {
  width: 81.5%;
  top: 68%;
  left: 3.5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberB {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberB {
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberB {
    font-size: 2.7rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberB {
  width: 81.5%;
  top: 68%;
  left: 3.5%;
  font-size: 1.2rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberB {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberB {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberB {
    font-size: 9px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberC {
  width: 55.5%;
  top: 68.5%;
  right: 9.5%;
  font-size: 2.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberC {
    font-size: 2.35rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberC {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .mySlides .phoneNumberC {
    font-size: 1.9rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberC {
  width: 55.5%;
  top: 68.5%;
  right: 9.5%;
  font-size: 0.9rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberC {
    font-size: 7px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc5 .tc-col .phoneNumberC {
    font-size: 6px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .loadPhoneNumber {
  font-family: 'Croissant One', serif;
  font-weight: 100;
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberA {
  width: 75%;
  top: 60%;
  left: 5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberA {
    font-size: 3.15rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberA {
    font-size: 3.1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberA {
    font-size: 2.6rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberA {
  width: 75%;
  top: 60%;
  left: 5%;
  font-size: 1.275rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberA {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberA {
    font-size: 8px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberB {
  width: 81.5%;
  top: 64%;
  left: 3.5%;
  font-size: 3.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberB {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberB {
    font-size: 3.25rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberB {
    font-size: 2.7rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberB {
  width: 81.5%;
  top: 64%;
  left: 3.5%;
  font-size: 1.2rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberB {
    font-size: 1rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberB {
    font-size: 1rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberB {
    font-size: 9px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberC {
  width: 55.5%;
  top: 66%;
  right: 9.5%;
  font-size: 2.8rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberC {
    font-size: 2.35rem;
  }
}
@media screen and (max-width: 480px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberC {
    font-size: 2.3rem;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .mySlides .phoneNumberC {
    font-size: 1.9rem;
  }
}
body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberC {
  width: 55.5%;
  top: 66%;
  right: 9.5%;
  font-size: 0.9rem;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberC {
    font-size: 7px;
  }
}
@media screen and (max-width: 350px) {
  body.table-card-shop .tc-main-container .tc-image-box.tbc6 .tc-col .phoneNumberC {
    font-size: 6px;
  }
}
body.table-card-shop .tc-main-container .tc-image-box .mySlides img,
body.table-card-shop .tc-main-container .tc-image-box .tc-col img.demo {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
body.table-card-shop .tc-main-container .tc-image-box .mySlides img.overlay,
body.table-card-shop .tc-main-container .tc-image-box .tc-col img.demo.overlay {
  position: absolute;
  z-index: 5;
}
body.table-card-shop .tc-main-container .tc-image-box .demo {
  cursor: pointer;
}
body.table-card-shop .tc-main-container .tc-image-box .tc-section {
  max-width: 500px;
  margin: auto;
}
body.table-card-shop .tc-main-container .tc-image-box .tc-col {
  display: inline-block;
  width: 33%;
  position: relative;
}
@media screen and (max-width: 850px) {
  body.table-card-shop .tc-main-container .tc-image-box .tc-col {
    width: 32%;
  }
}
body.table-card-shop .tc-main-container .confirm-overlay {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 314px;
  height: 288px;
  margin: -150px 0 0 -157px;
  border-radius: 6px;
  background-color: #f5f5f7;
  box-sizing: border-box;
  padding: 15px 20px;
  overflow: hidden;
  box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5), inset 0 1px 0 0 hsla(0, 0%, 100%, 0.65);
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .message-1,
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .message-2 {
  position: absolute;
  width: calc(100% - 40px);
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .message-2 {
  display: none;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box input {
  border-radius: 32px;
  box-sizing: border-box;
  padding: 8px 10px;
  font-family: 'Bodoni Egypt W01 Light Italic';
  color: #505050;
  font-size: 30px;
  text-align: center;
  border: 1px solid #c2c2c2;
  width: 100%;
  outline: none;
  background-color: #f6f6f6;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .lets-go,
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .lets-go2 {
  display: none;
  width: calc(100% - 20px);
  margin-top: 10px;
  cursor: pointer;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box p {
  margin: 10px 0px;
  font-size: 1.85rem;
  line-height: 2.2rem;
  text-align: center;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .num-check {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.6rem;
  font-weight: 700;
  text-align: center;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .confirm-btn-container {
  width: 100%;
  margin-top: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .confirm-btn-container .check-btn {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 3rem;
  box-sizing: border-box;
  padding-bottom: 8px;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .confirm-btn-container .check-btn.cby {
  background-color: #02C3D9;
  color: #013b41;
}
body.table-card-shop .tc-main-container .confirm-overlay .confirm-box .confirm-btn-container .check-btn.cbn {
  background-color: #d9aa02;
  color: #413301;
}
/* ============================================== */
/* ============= Automated Signshop ============= */
/* ============= Automated Signshop ============= */
/* ============= Automated Signshop ============= */
/* ============================================== */
/* ask for number opening screen */
.wed-sign-shop h1 {
  max-width: 800px;
  margin: auto;
}
.no-number-welcome {
  opacity: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-color: rgba(141, 206, 202, 0.9);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.no-number-welcome .no-num-box {
  height: 300px;
  width: 100%;
  max-width: 400px;
  border-radius: 22px;
  background-color: #fff;
  box-sizing: border-box;
  padding: 25px;
  position: relative;
}
.no-number-welcome .no-num-box h2 {
  font-family: 'Aleo', serif;
  color: #7d7d7d;
  margin: 10px 0 0;
  font-size: 2rem;
  line-height: 3rem;
}
.no-number-welcome .no-num-box .enter-change-num {
  width: 100%;
  margin: 14px auto;
}
.no-number-welcome .no-num-box .enter-change-num input {
  border-radius: 32px;
  box-sizing: border-box;
  padding: 8px 10px;
  font-family: 'Aleo', serif;
  color: #505050;
  font-size: 30px;
  text-align: center;
  border: 1px solid #c2c2c2;
  width: 100%;
  outline: none;
  background-color: #F6F6F6;
}
.no-number-welcome .no-num-box .enter-change-num .lets-go,
.no-number-welcome .no-num-box .enter-change-num .lets-go2 {
  display: none;
  border-radius: 32px;
  box-sizing: border-box;
  padding: 14px 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  color: #fff;
  font-size: 22px;
  text-align: center;
  border: 1px solid #d9aa02;
  width: 100%;
  outline: none;
  background-color: #d9aa02;
  margin: 10px auto 0;
}
.no-number-welcome .no-num-box .enter-change-num .lets-go:hover,
.no-number-welcome .no-num-box .enter-change-num .lets-go2:hover {
  cursor: pointer;
}
.no-number-welcome .no-num-box .just-browsing {
  position: absolute;
  font-size: 1.75rem;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translatex(-50%);
  -moz-transform: translatex(-50%);
  -ms-transform: translatex(-50%);
  transform: translatex(-50%);
  color: #d9aa02;
  font-family: 'Aleo', serif;
  font-weight: 900;
}
.no-number-welcome .no-num-box .just-browsing:hover {
  cursor: pointer;
  text-decoration: underline;
}
.video-library .content-container,
.live-display-setup .content-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  min-height: 80vh;
  width: 100%;
  max-width: 850px;
  margin: 100px auto 0;
}
@media screen and (max-width: 480px) {
  .video-library .content-container,
  .live-display-setup .content-container {
    margin: 90px auto;
  }
}
.video-library .content-container .vid-content-container,
.live-display-setup .content-container .vid-content-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-wrap: wrap;
  -moz-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
@media screen and (max-width: 800px) {
  .video-library .content-container .vid-content-container,
  .live-display-setup .content-container .vid-content-container {
    box-sizing: border-box;
    padding: 12px;
  }
}
@media screen and (max-width: 480px) {
  .video-library .content-container .vid-content-container,
  .live-display-setup .content-container .vid-content-container {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.video-library .content-container .vid-content-container .vid-container,
.live-display-setup .content-container .vid-content-container .vid-container {
  text-align: center;
  margin: 20px 0 10px;
  position: relative;
  width: 31%;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-align-self: flex-start;
  align-self: flex-start;
}
@media screen and (max-width: 480px) {
  .video-library .content-container .vid-content-container .vid-container,
  .live-display-setup .content-container .vid-content-container .vid-container {
    width: 100%;
  }
}
.video-library .content-container .vid-content-container .vid-container p,
.live-display-setup .content-container .vid-content-container .vid-container p {
  margin-top: 4px;
  font-size: 1.6rem;
  line-height: 2rem;
}
.video-library .content-container .vid-content-container .vid-container .vid-thumb-container,
.live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container {
  margin-bottom: 6px;
  cursor: pointer;
  width: 100%;
}
@media screen and (max-width: 800px) {
  .video-library .content-container .vid-content-container .vid-container .vid-thumb-container,
  .live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container {
    max-width: 46vw;
  }
}
@media screen and (max-width: 480px) {
  .video-library .content-container .vid-content-container .vid-container .vid-thumb-container,
  .live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container {
    width: 90vw;
    max-width: 90vw;
  }
}
.video-library .content-container .vid-content-container .vid-container .vid-thumb-container img,
.live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container img {
  width: 100%;
  height: auto;
}
.video-library .content-container .vid-content-container .vid-container .vid-thumb-container img.play-overlay,
.live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container img.play-overlay {
  position: absolute;
  top: 68px;
  left: 50%;
  height: 100px;
  width: auto;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media screen and (max-width: 480px) {
  .video-library .content-container .vid-content-container .vid-container .vid-thumb-container img.play-overlay,
  .live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container img.play-overlay {
    top: 85px;
  }
}
@media screen and (max-width: 350px) {
  .video-library .content-container .vid-content-container .vid-container .vid-thumb-container img.play-overlay,
  .live-display-setup .content-container .vid-content-container .vid-container .vid-thumb-container img.play-overlay {
    top: 75px;
  }
}
.lets-see-modal {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.lets-see-modal #modal-img-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  height: 100vh;
  width: 100vw;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.lets-see-modal #modal-img-container iframe {
  position: absolute;
  text-align: center;
  max-width: 100vw;
  height: 100vh;
  max-height: 630px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 480px) {
  .lets-see-modal #modal-img-container iframe {
    height: 50vh;
  }
}
.lets-see-modal #modal-img-container video {
  position: absolute;
  text-align: center;
  max-width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 480px) {
  .lets-see-modal #modal-img-container video {
    height: 100vh;
    max-width: 100vw;
  }
}
.lets-see-modal .close-modal {
  cursor: pointer;
  position: absolute;
  left: 0%;
  text-align: center;
  color: #fff;
  padding: 9px 14px;
  background-color: #7d7d7d;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 9000;
  z-index: 100;
}
.lets-see-modal .ship-info-modal-box {
  width: 90vw;
  max-width: 480px;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.lets-see-modal .modal-header {
  margin-bottom: 0px;
}
.lets-see-modal .modal-p {
  text-align: left;
  margin-top: 15px;
  margin-bottom: 0;
  line-height: 24px;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .lets-see-modal .modal-p {
    box-sizing: border-box;
    padding: 0 18px;
  }
}
.lets-see-modal .modal-p:nth-last-of-type(1) {
  margin-bottom: auto;
}
.lets-see-modal #modal-img-container .modal-vid-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  position: relative;
}
.lets-see-modal #modal-img-container .modal-vid-container div {
  box-sizing: border-box;
  padding: 15px;
  width: 23.5%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 100%;
}
@media screen and (max-width: 850px) {
  .lets-see-modal #modal-img-container .modal-vid-container div {
    width: 100%;
    height: auto;
  }
}
.lets-see-modal #modal-img-container .modal-vid-container div ul li {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 850px) {
  .lets-see-modal #modal-img-container .modal-vid-container div ul li {
    font-size: 1.3rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    font-family: 'Montserrat', sans-serif;
  }
}
@media screen and (max-width: 350px) {
  .lets-see-modal #modal-img-container .modal-vid-container div ul li {
    font-size: 1.2rem;
    letter-spacing: 0.025em;
    line-height: 1.55rem;
    margin-bottom: 0.7rem;
  }
}
.lets-see-modal #modal-img-container .modal-vid-container video {
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  transform: none;
  position: relative;
  top: inherit;
  left: inherit;
}
@media screen and (max-width: 850px) {
  .lets-see-modal #modal-img-container .modal-vid-container {
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.lets-see-modal #modal-img-container .modal-square-vid-container video {
  width: 60%;
  height: auto;
  padding-right: 16.5%;
}
@media screen and (max-width: 850px) {
  .lets-see-modal #modal-img-container .modal-square-vid-container video {
    width: 100%;
    padding-right: 0;
  }
}
.lets-see-modal #modal-img-container .modal-landscape-vid-container video {
  width: 70%;
  height: auto;
  padding-right: 5%;
}
@media screen and (max-width: 850px) {
  .lets-see-modal #modal-img-container .modal-landscape-vid-container video {
    width: 100%;
    padding-right: 0;
  }
}
/* =========== TESTIMONIALS ============= */
/* =========== TESTIMONIALS ============= */
/* =========== TESTIMONIALS ============= */
/* =========== TESTIMONIALS ============= */
.lp-container.testimonials {
  margin-bottom: 50px;
}
.lp-container.testimonials h1 {
  text-align: center;
}
.testimonialp-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  padding-bottom: 32px;
  border-bottom: 1px dashed #ebebeb;
}
.testimonialp-box:nth-last-of-type(1) {
  border-bottom: none;
}
.testimonialp-box:nth-last-of-type(odd) {
  -webkit-box-flex-direction: row-reverse;
  -moz-box-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 480px) {
  .testimonialp-box:nth-last-of-type(odd) {
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .testimonialp-box:nth-last-of-type(even) {
    -webkit-box-flex-direction: column-reverse;
    -moz-box-flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
.testimonialp-box .testimonial-copy-container {
  width: 75%;
  box-sizing: border-box;
  padding: 0 5px;
}
@media only screen and (max-width: 480px) {
  .testimonialp-box .testimonial-copy-container {
    width: 100%;
    padding: 10px 5% 5%;
    box-sizing: border-box;
  }
}
.testimonialp-box .testimonial-copy-container .person {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin: 12px 0 0;
}
.testimonialp-box .testimonial-copy-container .from {
  margin-top: 0px;
  font-size: 1.5rem;
}
.testimonialp-box .testimonial-copy-container .extra-info {
  margin-top: 4px;
  font-size: 1.5rem;
  margin-bottom: 0px;
}
.testimonialp-box .testimonial-copy-container .extra-info a {
  margin-top: 4px;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
  color: #d9aa02;
}
.testimonialp-box .testimonial-image-container {
  width: 20%;
  padding-top: 30px;
}
@media only screen and (max-width: 480px) {
  .testimonialp-box .testimonial-image-container {
    width: 100%;
    padding: 0;
    box-sizing: border-box;
  }
}
.testimonialp-box .testimonial-image-container .headshot {
  width: 175px;
  height: 175px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  margin: auto;
}
.testimonialp-box .testimonial-image-container .headshot img {
  width: 175px;
  height: 175px;
}
@media screen and (max-width: 770px) {
  .testimonialp-box .testimonial-image-container .headshot {
    width: 150px;
    height: 150px;
  }
  .testimonialp-box .testimonial-image-container .headshot img {
    width: 150px;
    height: 150px;
  }
}
@media screen and (max-width: 480px) {
  .testimonialp-box .testimonial-image-container .headshot {
    margin-bottom: 20px;
  }
}
.more-test-avail-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;
}
.more-test-avail-container a {
  text-align: center;
  padding: 20px 30px;
  text-decoration: none;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-radius: 55px;
  font-size: 1.9rem;
  background-color: #9ad0ee;
}
@media screen and (max-width: 480px) {
  .more-test-avail-container a {
    font-size: 1.4rem;
  }
}
.more-test-avail-container a img {
  height: 25px;
  margin-left: 6px;
  margin-top: -7px;
}
/* =========== WELCOME OVERVIEW ============= */
/* =========== WELCOME OVERVIEW ============= */
/* =========== WELCOME OVERVIEW ============= */
/* =========== WELCOME OVERVIEW ============= */
.welcome-overview {
  margin-bottom: 25px;
}
.welcome-overview ul {
  list-style: none;
  padding: 0;
  font-size: 2rem;
}
.welcome-overview ul ol.inner {
  list-style-type: decimal;
  list-style-position: inherit;
  margin-left: 5px;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
@media only screen and (max-width: 480px) {
  .welcome-overview ul ol.inner {
    padding-bottom: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
}
.welcome-overview ul .inner {
  padding-left: 1em;
  box-sizing: border-box;
  overflow: hidden;
  display: none;
  margin-bottom: 25px;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .welcome-overview ul .inner {
    padding-left: 0.1em;
  }
}
.welcome-overview ul .inner li {
  border-bottom: solid 1px #ebebeb;
  padding-bottom: 12px;
  list-style: none;
  color: #7d7d7d;
  font-family: 'Aleo', serif;
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 2.5rem;
}
@media only screen and (max-width: 480px) {
  .welcome-overview ul .inner li {
    font-size: 1.55rem;
  }
}
.welcome-overview ul .inner li:nth-last-of-type(1) {
  border-bottom: none;
}
.welcome-overview ul .inner li img {
  display: block;
  margin-top: 4px;
  width: 100%;
  max-width: 600px;
}
@media only screen and (max-width: 800px) {
  .welcome-overview ul .inner li img {
    width: 100%;
  }
}
.welcome-overview ul .inner li span {
  font-weight: 900;
}
.welcome-overview ul .inner li.note {
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.welcome-overview ul .inner li.note span {
  font-weight: 900;
}
.welcome-overview ul .inner li.inner-child {
  border-bottom: none;
  padding-bottom: 0px;
  font-size: 2rem;
  width: 94%;
  margin: 0.5em auto;
}
.welcome-overview ul .inner li.inner-child ol.inner {
  padding-left: 0px;
  margin-left: 5px;
}
.welcome-overview ul li {
  margin: 0.5em 0;
}
.welcome-overview ul li a.toggle {
  width: 100%;
  display: block;
  background: #ebebeb;
  color: #505050;
  padding: 1em;
  box-sizing: border-box;
  border-radius: 0.15em;
  transition: all 0.3s ease;
  text-decoration: none;
  font-family: 'Aleo', serif;
  font-size: 1.7rem;
  font-weight: 400;
}
@media only screen and (max-width: 480px) {
  .welcome-overview ul li a.toggle {
    font-size: 1.5rem;
  }
}
.welcome-overview ul li a.toggle:hover {
  background: #02C3D9;
  color: #fff;
}
.welcome-overview ul li a.last-toggle {
  width: 100%;
  display: block;
  padding: 0.75em;
  box-sizing: border-box;
  border-radius: 0.15em;
  transition: all 0.3s ease;
  text-decoration: none;
  background-color: #02C3D9;
  color: #fff;
  font-weight: 900;
}
#lp01 {
  padding: 3% 5%;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 80vh;
  background-color: #ffffff;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 800px) {
  #lp01 {
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
    padding-top: 10%;
    background-image: none !important;
    height: 72vh;
    width: 100%;
    min-height: 590px;
  }
}
@media screen and (max-width: 480px) {
  #lp01 {
    padding-top: 20%;
    min-height: 550px;
    height: 85vh;
  }
}
@media screen and (max-width: 350px) {
  #lp01 {
    padding-top: 20%;
    height: 65vh;
    min-height: 500px;
  }
}
#lp01 .bgvid {
  position: absolute;
  height: 100%;
  top: 50px;
  right: 0;
  z-index: 0;
}
@media screen and (max-width: 800px) {
  #lp01 .bgvid {
    top: inherit;
    bottom: 0;
    left: 0;
  }
}
#lp01 .bgvid img {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 0;
}
@media screen and (max-width: 800px) {
  #lp01 .bgvid img {
    width: 100%;
    height: auto;
    bottom: 0;
    top: inherit;
  }
}
#lp01 .zup {
  z-index: 10;
  max-width: 50%;
}
@media screen and (max-width: 480px) {
  #lp01 .zup {
    max-width: 100%;
  }
}
#lp01 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.6rem;
  font-weight: 300;
  line-height: 4.5rem;
  color: #7d7d7d;
  letter-spacing: -0.04em;
  margin-bottom: 0;
}
@media screen and (max-width: 1560px) {
  #lp01 h1 {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 1440px) {
  #lp01 h1 {
    font-size: 3.6rem;
  }
}
@media screen and (max-width: 1366px) {
  #lp01 h1 {
    font-size: 3.4rem;
  }
}
@media screen and (max-width: 800px) {
  #lp01 h1 {
    font-size: 3.8rem;
  }
}
@media screen and (max-width: 480px) {
  #lp01 h1 {
    font-size: 2.7rem;
    line-height: 3.4rem;
  }
}
@media screen and (max-width: 350px) {
  #lp01 h1 {
    font-size: 2.25rem;
    line-height: 3.3rem;
  }
}
#lp01 h1 span {
  font-family: 'Aleo', serif;
  font-size: 3.8rem;
  font-weight: 400;
  color: #02C3D9;
  display: block;
  letter-spacing: 1px;
  margin: 1rem 0 2rem 0;
}
@media screen and (max-width: 480px) {
  #lp01 h1 span {
    font-size: 2.8rem;
    line-height: 4rem;
    padding-top: 0.75rem;
  }
}
@media screen and (max-width: 350px) {
  #lp01 h1 span {
    font-size: 2.6rem;
    line-height: 3.3rem;
  }
}
#lp01 .banner-sale-msg {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 4rem;
  color: #d90263;
}
@media screen and (max-width: 480px) {
  #lp01 .banner-sale-msg {
    font-size: 1.65rem;
    line-height: 2rem;
  }
}
#lp01 .banner-sale-msg span.sale-exp {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  margin: 0.1rem 0 2rem 0;
  letter-spacing: -0.025em;
  color: #7d7d7d;
}
@media screen and (max-width: 480px) {
  #lp01 .banner-sale-msg span.sale-exp {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 0rem 0 2rem 0;
    line-height: 1.3rem;
  }
}
#lp01 p {
  display: block;
  width: 50%;
  font-family: 'Aleo', serif;
  font-size: 2.3rem;
  font-weight: 300;
  line-height: 3.4rem;
  color: #505050;
  margin: 20px 0 30px;
}
#lp01 p span {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  display: block;
  padding-top: 10px;
}
#lp01 p span i {
  color: #02C3D9;
  font-style: normal;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}
@media screen and (max-width: 480px) {
  #lp01 p span {
    font-size: inherit;
    font-family: inherit;
  }
}
@media screen and (max-width: 800px) {
  #lp01 p {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  #lp01 p {
    width: 100%;
    font-size: 1.75rem;
    line-height: 2.6rem;
    margin: 25px 0 20px;
    letter-spacing: -0.015em;
    word-spacing: 0.05em;
  }
}
@media screen and (max-width: 350px) {
  #lp01 p {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}
#lp01 .video {
  height: 60px;
  width: auto;
  border-radius: 30px;
  background-color: #d9aa02;
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 5px 5px 5px 15px;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media screen and (max-width: 480px) {
  #lp01 .video {
    height: 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 5px 4px 5px 15px;
  }
}
@media screen and (max-width: 350px) {
  #lp01 .video {
    height: 40px;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    font-weight: 500;
    padding: 5px 3px 5px 15px;
  }
}
#lp01 .video span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: solid 2px #d9aa02;
  margin-left: 10px;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media screen and (max-width: 480px) {
  #lp01 .video span {
    height: 40px;
    width: 40px;
  }
}
@media screen and (max-width: 350px) {
  #lp01 .video span {
    height: 36px;
    width: 36px;
  }
}
#lp01 .video span div {
  width: 0;
  height: 0;
  border-right: 17px solid transparent;
  border-bottom: 12px solid transparent;
  border-top: 12px solid transparent;
  border-left: 17px solid #d9aa02;
  -webkit-transform: translate(33%);
  -moz-transform: translate(33%);
  -ms-transform: translate(33%);
  transform: translate(33%);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
@media screen and (max-width: 480px) {
  #lp01 .video span div {
    border-right: 13px solid transparent;
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
  }
}
#lp01 .video:hover {
  background-color: #fff;
  background-color: #02C3D9;
  color: #fff;
}
#lp01 .video:hover span {
  border: solid 2px #02C3D9;
  background-color: #fff;
}
#lp01 .video:hover span div {
  border-left: 17px solid #02C3D9;
}
#how,
#also-includes,
#what-you-need {
  box-sizing: border-box;
  padding: 50px 0 100px;
}
#how h2,
#also-includes h2,
#what-you-need h2 {
  text-align: center;
}
#how h3,
#also-includes h3,
#what-you-need h3 {
  margin-bottom: 40px;
}
#how .splits,
#also-includes .splits,
#what-you-need .splits {
  max-width: 1400px;
  margin: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 850px) {
  #how .splits,
  #also-includes .splits,
  #what-you-need .splits {
    -webkit-box-flex-wrap: wrap;
    -moz-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 480px) {
  #how .splits,
  #also-includes .splits,
  #what-you-need .splits {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#how .third,
#also-includes .third,
#what-you-need .third {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  width: 33%;
}
@media screen and (max-width: 480px) {
  #how .third,
  #also-includes .third,
  #what-you-need .third {
    margin-bottom: 25px;
    width: 100%;
  }
  #how .third:nth-last-of-type(1) p,
  #also-includes .third:nth-last-of-type(1) p,
  #what-you-need .third:nth-last-of-type(1) p {
    border-bottom: none;
    margin-bottom: 0;
  }
}
#how .third .feature-icon,
#also-includes .third .feature-icon,
#what-you-need .third .feature-icon {
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
  padding: 50px;
  box-sizing: border-box;
}
#how .forth,
#also-includes .forth,
#what-you-need .forth {
  margin-bottom: 20px;
  padding-bottom: 30px;
  position: relative;
  width: 25%;
  text-align: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}
@media screen and (max-width: 850px) {
  #how .forth,
  #also-includes .forth,
  #what-you-need .forth {
    width: 50%;
    display: inline-block;
  }
}
@media screen and (max-width: 480px) {
  #how .forth,
  #also-includes .forth,
  #what-you-need .forth {
    width: 100%;
    background-image: -o-linear-gradient(top, #fef7f7 0%, #ffffff 100%);
    background-image: -moz-linear-gradient(top, #fef7f7 0%, #ffffff 100%);
    background-image: -webkit-linear-gradient(top, #fef7f7 0%, #ffffff 100%);
    background-image: -ms-linear-gradient(top, #fef7f7 0%, #ffffff 100%);
    background-image: linear-gradient(to top, #fef7f7 0%, #ffffff 100%);
  }
}
#how .forth img,
#also-includes .forth img,
#what-you-need .forth img {
  width: auto;
  margin: 0 auto;
  max-height: 200px;
}
#how h4,
#also-includes h4,
#what-you-need h4 {
  width: 90%;
  border-bottom: solid 1px #d9aa02;
  text-align: center;
  margin: 10px auto;
  padding: 10px 0;
}
#how h4 span,
#also-includes h4 span,
#what-you-need h4 span {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #fff;
  background-color: #d9aa02;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  font-size: 2.4rem;
  margin-right: 4px;
}
#how p,
#also-includes p,
#what-you-need p {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 480px) {
  #how p,
  #also-includes p,
  #what-you-need p {
    padding-bottom: 25px;
  }
}
#how p span,
#also-includes p span,
#what-you-need p span {
  display: block;
  cursor: pointer;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-family: 'Montserrat', sans-serif;
  padding: 4px 18px;
  background-color: #fff;
  color: #7d7d7d;
  font-weight: 500;
  border-radius: 20px;
  transition: 0.33s;
  width: 110px;
  margin: 18px auto 0;
  border: dotted 1px #ccc;
}
#how p span:hover,
#also-includes p span:hover,
#what-you-need p span:hover {
  background-color: #02C3D9;
  color: #fff;
  font-weight: 900;
}
@media screen and (max-width: 480px) {
  #how p span,
  #also-includes p span,
  #what-you-need p span {
    background-color: #02C3D9;
    border: dotted 1px #02C3D9;
    color: #fff;
    font-weight: 500;
  }
}
#how.live-setup .third .feature-icon,
#also-includes.live-setup .third .feature-icon,
#what-you-need.live-setup .third .feature-icon,
#how.live-setup .forth .feature-icon,
#also-includes.live-setup .forth .feature-icon,
#what-you-need.live-setup .forth .feature-icon {
  height: auto;
  width: auto;
}
#how.live-setup .third .feature-icon.how-to,
#also-includes.live-setup .third .feature-icon.how-to,
#what-you-need.live-setup .third .feature-icon.how-to,
#how.live-setup .forth .feature-icon.how-to,
#also-includes.live-setup .forth .feature-icon.how-to,
#what-you-need.live-setup .forth .feature-icon.how-to {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: block;
  border: solid #ebebeb 1px;
}
#how.live-setup .third .feature-icon.how-to img,
#also-includes.live-setup .third .feature-icon.how-to img,
#what-you-need.live-setup .third .feature-icon.how-to img,
#how.live-setup .forth .feature-icon.how-to img,
#also-includes.live-setup .forth .feature-icon.how-to img,
#what-you-need.live-setup .forth .feature-icon.how-to img {
  height: 100%;
}
#how {
  padding: 150px 0 200px;
}
@media screen and (max-width: 480px) {
  #how {
    padding: 100px 0 150px;
  }
}
#how-to-set-up.live-setup-steps p {
  text-align: center;
}
#how-to-set-up.live-setup-steps h2 {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 0;
}
#how-to-set-up.live-setup-steps .step-container .step {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: 65px;
  padding-bottom: 65px;
  border-bottom: dotted 1px #c2c2c2;
}
#how-to-set-up.live-setup-steps .step-container .step h4 {
  margin: 0 auto 32px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#how-to-set-up.live-setup-steps .step-container .step h4 span {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  background-color: #02C3D9;
  color: #fff;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 50%;
}
#how-to-set-up.live-setup-steps .step-container .step p {
  margin-top: 4px;
  text-align: left;
}
#how-to-set-up.live-setup-steps .step-container .step .img-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: row;
  -moz-box-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  width: 100%;
}
@media screen and (max-width: 480px) {
  #how-to-set-up.live-setup-steps .step-container .step .img-container {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
#how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
#how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon img {
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px #c2c2c2;
  width: 250px;
  height: 250px;
}
@media screen and (max-width: 850px) {
  #how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon img {
    width: 160px;
    height: 160px;
  }
}
@media screen and (max-width: 480px) {
  #how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon img {
    width: 250px;
    height: 250px;
  }
}
#how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon p {
  max-width: 240px;
  font-size: 1.4rem;
  line-height: 1.8rem;
}
@media screen and (max-width: 850px) {
  #how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon p {
    max-width: 150px;
  }
}
@media screen and (max-width: 480px) {
  #how-to-set-up.live-setup-steps .step-container .step .img-container .feature-icon p {
    max-width: 240px;
  }
}
#tips-for-success {
  width: 100%;
  border: solid 2px #d9aa02;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff7db;
  margin-bottom: 50px;
}
#tips-for-success h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4rem;
  font-weight: 300;
  margin: 0 0 15px 0;
  font-size: 2.6rem;
  text-align: center;
}
#tips-for-success ol {
  list-style: decimal;
  list-style-position: inside;
  margin-left: 10px;
}
#tips-for-success ol li {
  font-size: 1.8rem;
  margin-bottom: 0.8rem;
  color: #505050;
}
#tips-for-success ol li span {
  font-weight: 900;
  color: #d9aa02;
}
#tips-for-success ol li p {
  font-size: 1.8rem;
  margin: 2px;
  padding-left: 40px;
}
/* =========== TRY TacBoard , Contact Us ============= */
.try-it-container,
.contact-us-container {
  margin: 6% auto;
  min-height: 75vh;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
  padding: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}
.try-it-container h2.into-line,
.contact-us-container h2.into-line {
  margin-bottom: 26px;
  font-family: 'Montserrat', sans-serif;
  font-size: 3.6rem;
  font-weight: 300;
  color: #7d7d7d;
}
.try-it-container p,
.contact-us-container p {
  margin-bottom: 0px;
}
.try-it-container p a,
.contact-us-container p a {
  color: #d9aa02;
  font-weight: 500;
  text-decoration: none;
}
.try-it-container .try-msg,
.contact-us-container .try-msg {
  width: 100%;
}
.try-it-container .try-msg h3,
.contact-us-container .try-msg h3 {
  color: #02C3D9;
  font-weight: 700;
}
.try-it-container a.phone-link,
.contact-us-container a.phone-link {
  font-size: 56px;
  text-decoration: none;
  color: #02C3D9;
  margin-bottom: 20px;
}
@media only screen and (max-width: 420px) {
  .try-it-container a.phone-link,
  .contact-us-container a.phone-link {
    font-size: 50px;
    margin: 14px 0 24px;
  }
}
@media only screen and (max-width: 360px) {
  .try-it-container a.phone-link,
  .contact-us-container a.phone-link {
    font-size: 40px;
    margin: 10px 0 24px;
  }
}
.try-it-container {
  margin-bottom: 0;
}
.rev-calc-container {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  max-width: 900px;
}
@media screen and (max-width: 480px) {
  .rev-calc-container {
    padding: 20px 0;
  }
}
.rev-calc-container h3 {
  border-bottom: none;
  width: 90%;
}
.rev-calc-container .rev-calc {
  width: 100%;
  border: solid 1px #c2c2c2;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 20px 30px;
  margin: 0 auto;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc {
    padding: 20px 16px;
  }
}
.rev-calc-container .rev-calc .slider-row {
  width: 100%;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  padding: 15px 0;
}
.rev-calc-container .rev-calc .slider-row:nth-of-type(2) {
  margin-bottom: 0.75rem;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .slider-row {
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .rev-calc-container .rev-calc .slider-row p::after {
    content: 'Enter your info:';
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    color: #959595;
  }
}
.rev-calc-container .rev-calc .slider-row p {
  width: 75%;
  display: inline-block;
  margin: 0;
  color: #505050;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 500;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .slider-row p {
    width: 90%;
  }
}
.rev-calc-container .rev-calc .slider-row .slider-container {
  width: 25%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .slider-row .slider-container {
    width: 90%;
    -webkit-justify-content: flex-start;
    -moz-justify-content: flex-start;
    -ms-justify-content: flex-start;
    justify-content: flex-start;
  }
  .rev-calc-container .rev-calc .slider-row .slider-container input {
    margin-top: 6px;
    padding: 4px 12px;
    border: solid 1px #c2c2c2;
    font-family: 'Montserrat', sans-serif;
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
  }
}
.rev-calc-container .rev-calc .slider-row .slider-container .sellFor,
.rev-calc-container .rev-calc .slider-row .slider-container .sellMonth {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 500;
  color: #02C3D9;
  padding: 0 6px;
  min-width: 100px;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .slider-row .slider-container .sellFor,
  .rev-calc-container .rev-calc .slider-row .slider-container .sellMonth {
    display: none;
  }
}
.rev-calc-container .rev-calc .value-row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  margin: 0 auto 1.5rem;
  padding: 1.5em 0 0 0;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  border-top: dotted 1px #c2c2c2;
}
.rev-calc-container .rev-calc .value-row .row-label-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.rev-calc-container .rev-calc .value-row .row-label-box .label {
  font-family: 'Montserrat', sans-serif;
  font-size: 3.2rem;
  font-weight: 400;
  color: #7d7d7d;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .value-row .row-label-box .label {
    font-size: 1.8rem;
  }
}
.rev-calc-container .rev-calc .value-row .row-label-box .sub-label {
  font-family: 'Aleo', serif;
  font-size: 1.4rem;
  font-weight: 400;
  color: #7d7d7d;
  padding-top: 0.25rem;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .value-row .row-label-box .sub-label {
    font-size: 1.3rem;
  }
}
.rev-calc-container .rev-calc .value-row > * {
  width: 33%;
}
.rev-calc-container .rev-calc .rov-value-box {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  -moz-align-items: baseline;
  -ms-align-items: baseline;
  align-items: baseline;
}
.rev-calc-container .rev-calc .rov-value-box div:first-child {
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  font-weight: 700;
  color: #02C3D9;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .rov-value-box div:first-child {
    font-size: 1.8rem;
  }
}
.rev-calc-container .rev-calc .rov-value-box div:last-child {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  color: #02C3D9;
}
@media screen and (max-width: 480px) {
  .rev-calc-container .rev-calc .rov-value-box div:last-child {
    font-size: 1.3rem;
  }
}
.rev-calc-container .calc-rows {
  max-height: 0px;
  overflow: hidden;
  width: 100%;
  -webkit-transition: all 0.33s;
  -moz-transition: all 0.33s;
  -ms-transition: all 0.33s;
  -o-transition: all 0.33s;
  transition: all 0.33s;
}
.rev-calc-container .calc-row-show {
  max-height: 600px;
}
.diy-insp-container {
  width: 99vw;
  max-width: 100%;
  position: relative;
}
.diy-insp-container h1 {
  text-align: center;
  margin: auto;
}
.diy-insp-container h3 {
  width: 90%;
  max-width: 900px;
  text-align: center;
  margin: 20px auto 30px;
}
.diy-insp-container .diy-grid-container {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  justify-content: space-around;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
}
@media screen and (max-width: 480px) {
  .diy-insp-container .diy-grid-container {
    flex-direction: column;
  }
}
.diy-insp-container .insp-grid {
  width: 32.5%;
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-gap: 12px;
  position: relative;
  justify-self: start;
}
@media screen and (max-width: 480px) {
  .diy-insp-container .insp-grid {
    width: 100%;
    margin-bottom: 4px;
  }
}
.diy-insp-container .diy-item-container,
.diy-insp-container a.diy-item-container {
  text-decoration: none;
  justify-self: start;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex-direction: column;
  -moz-box-flex-direction: column;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #333;
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
}
.diy-insp-container .diy-item-container .diy-item-info,
.diy-insp-container a.diy-item-container .diy-item-info {
  -webkit-align-self: flex-end;
  -moz-align-self: flex-end;
  -ms-align-self: flex-end;
  align-self: flex-end;
  background-color: rgba(255, 255, 255, 0.8);
}
.diy-insp-container .diy-item-container.pink,
.diy-insp-container a.diy-item-container.pink {
  background-color: pink;
}
.diy-insp-container .diy-item-container.aqua,
.diy-insp-container a.diy-item-container.aqua {
  background-color: #258082;
  color: #fff;
}
.diy-insp-container .diy-item-container.brown,
.diy-insp-container a.diy-item-container.brown {
  background-color: #816857;
  color: #fff;
}
.diy-insp-container .diy-item-container.cream,
.diy-insp-container a.diy-item-container.cream {
  background-color: #ecddcb;
}
.diy-insp-container .diy-item-container.lav,
.diy-insp-container a.diy-item-container.lav {
  background-color: #c3b0fd;
}
.diy-insp-container .diy-item-container.dark-grey,
.diy-insp-container a.diy-item-container.dark-grey {
  background-color: #505050;
  color: #fff;
}
.diy-insp-container .diy-item-container.rose,
.diy-insp-container a.diy-item-container.rose {
  background-color: #c72756;
  color: #fff;
}
.diy-insp-container .diy-item-container.gold,
.diy-insp-container a.diy-item-container.gold {
  background-color: #bebe97;
}
.diy-insp-container .diy-item-container img,
.diy-insp-container a.diy-item-container img {
  width: 100%;
}
.diy-insp-container .diy-item-container button,
.diy-insp-container a.diy-item-container button {
  padding: 4px 12px;
  outline: 0;
  border: 0;
  color: #fff;
  background-color: #02C3D9;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}
.diy-insp-container .diy-embed-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56%;
}
.diy-insp-container .youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.diy-insp-container .diy-copy-container {
  padding: 0 15px 15px;
  -webkit-align-self: flex-start;
  -moz-align-self: flex-start;
  -ms-align-self: flex-start;
  align-self: flex-start;
}
.diy-insp-container .diy-copy-container h4 {
  margin-top: 20px;
  text-transform: capitalize;
}
.diy-insp-container .diy-copy-container p.diy-by {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0;
}
.pb-compare-container {
  display: -ms-grid;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-rows: auto;
  margin-bottom: 50px;
  grid-row-gap: 0rem;
  grid-auto-rows: minmax(50px, auto);
  border-bottom: solid 2px #ebebeb;
}
.pb-compare-container .heading {
  border-bottom: solid 1px #c2c2c2;
}
.pb-compare-container h4 {
  margin: 0;
  text-align: center;
}
.pb-compare-container .center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
}
.pb-compare-container p.title {
  text-align: center;
  font-weight: 700;
}
.pb-compare-container p.title.top {
  padding-top: 8px;
}
.pb-compare-container .footnote {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  margin: 0 auto 2px;
}
.pb-compare-container p {
  margin: 2px auto 0;
  line-height: 2rem;
  padding-left: 15px;
}
.pb-compare-container ul {
  list-style: circle;
  list-style-position: outside;
  margin: 4px 0 0;
}
.pb-compare-container ul li {
  padding-bottom: 6px;
}
.pb-compare-container ul li ul li {
  font-size: 1.5rem;
  padding-bottom: 2px;
}
.pb-compare-container a {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  color: #d9aa02;
}
.pb-compare-container > div {
  padding: 12px;
}
.pb-compare-container div:nth-of-type(6n + 4),
.pb-compare-container div:nth-of-type(6n + 5),
.pb-compare-container div:nth-of-type(6n + 6) {
  background-color: #ebebeb;
}
/* ================================ */
/* ======= Vendor Overview ======== */
/* ================================ */
.venue-overview p a {
  color: #d9aa02;
  text-decoration: none;
  font-weight: 500;
}
#venue-hero {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  height: 60vh;
  min-height: 600px;
  background-image: url(https://tacboard.com/img/event.jpg);
  background-color: #ebebeb;
  background-attachment: fixed;
  background-size: 125%;
  background-position: left center;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
}
@media screen and (max-width: 800px) {
  #venue-hero {
    height: 50vh;
    min-height: 440px;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10% 5% 15%;
    background-size: auto 100%;
    background-position: left top;
    background-repeat: no-repeat;
    background-image: url(https://tacboard.com/img/event-m.jpg);
  }
}
@media screen and (max-width: 480px) {
  #venue-hero {
    height: 50vh;
    max-height: 420px;
    min-height: 400px;
    padding: 10% 10% 15%;
  }
}
#venue-hero.venue-hero-short {
  height: 40vh;
  min-height: 550px;
  background-image: url(https://tacboard.com/img/home_07-OG.jpg);
  background-size: 100% auto;
  background-position: right top;
}
@media screen and (max-width: 800px) {
  #venue-hero.venue-hero-short {
    height: 30vh;
    min-height: 430px;
    background-size: auto 100%;
    background-position: 75% center;
  }
}
@media screen and (max-width: 480px) {
  #venue-hero.venue-hero-short {
    height: 30vh;
    max-height: 320px;
    min-height: 300px;
    background-size: 150% auto;
    background-position: right 40px;
  }
}
#venue-hero .cover-shape {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 0;
  background-color: rgba(240, 240, 240, 0.9);
}
@media screen and (max-width: 480px) {
  #venue-hero .cover-shape {
    background-color: rgba(240, 240, 240, 0.9);
    border: 0;
    border-right: 53vh solid transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0%;
  }
}
#venue-hero .content-container {
  width: 100%;
  max-width: 1100px;
  margin: auto;
  position: relative;
  z-index: 1;
}
@media screen and (max-width: 480px) {
  #venue-hero .content-container {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    -moz-justify-content: flex-end;
    -ms-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
  }
}
#venue-hero .content-container h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 4.2rem;
  font-weight: 300;
  line-height: 5.4rem;
  color: #505050;
  letter-spacing: -0.025em;
  margin-bottom: 0;
}
@media screen and (max-width: 480px) {
  #venue-hero .content-container h1 {
    color: #333;
    font-size: 2.7rem;
    line-height: 4rem;
  }
}
@media screen and (max-width: 350px) {
  #venue-hero .content-container h1 {
    font-size: 2.4rem;
    line-height: 3.3rem;
  }
}
#venue-hero .content-container h1 span {
  font-family: 'Aleo', serif;
  font-size: 4rem;
  font-weight: 400;
  color: #02C3D9;
  display: block;
  letter-spacing: 1px;
}
@media screen and (max-width: 480px) {
  #venue-hero .content-container h1 span {
    font-size: 3.1rem;
    line-height: 4rem;
    padding-top: 8px;
  }
}
@media screen and (max-width: 350px) {
  #venue-hero .content-container h1 span {
    font-size: 2.55rem;
    line-height: 3.3rem;
  }
}
#venue-hero .content-container p {
  display: block;
  width: 100%;
  font-family: 'Aleo', serif;
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 4rem;
  color: #505050;
  margin: 15px 0 50px;
  max-width: 1100px;
}
#venue-hero .content-container p span {
  font-weight: 400;
  text-shadow: 3px 3px 5px #fff;
}
#venue-hero .content-container p span:nth-of-type(1) {
  color: #02C3D9;
  font-weight: 500;
  text-shadow: #fff 1px 0 10px;
}
#venue-hero .content-container p span:nth-of-type(2) {
  color: #d90263;
}
#venue-hero .content-container p span:nth-of-type(3) {
  color: #d9aa02;
}
#venue-hero .content-container p i {
  font-style: normal;
  color: #505050;
  font-weight: 400;
}
@media screen and (max-width: 800px) {
  #venue-hero .content-container p {
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 480px) {
  #venue-hero .content-container p {
    width: 100%;
    font-size: 1.8rem;
    line-height: 2.7rem;
    text-align: left;
    color: #444;
    margin: 15px 0 25px;
  }
}
@media screen and (max-width: 350px) {
  #venue-hero .content-container p {
    font-size: 1.7rem;
    line-height: 2.5rem;
  }
}
#venue-hero .content-container a {
  margin-left: 0;
}
.before-your-event .flex-container .half picture {
  text-align: right;
}
@media screen and (max-width: 480px) {
  .before-your-event .flex-container .half picture {
    text-align: center;
  }
}
.before-your-event .flex-container .half picture img {
  max-width: 350px;
}
#venue-inq-bar {
  position: relative;
  background-color: #d90263;
}
#venue-inq-bar h2.center {
  color: #fff;
  margin-bottom: 0;
}
@media screen and (max-width: 480px) {
  #venue-inq-bar h2.center {
    margin-bottom: 15px;
  }
}
#venue-inq-bar h3 {
  color: #fff;
  margin-bottom: 15px;
}
@media screen and (max-width: 480px) {
  #venue-inq-bar h3 {
    text-align: center;
  }
}
#venue-inq-bar a.get-tac-now {
  background-color: #fff;
  color: #d90263;
}
video.inline-video {
  box-shadow: 0px 0px 25px 0px rgba(50, 50, 50, 0.25);
  outline: 0;
}
.full-content {
  width: 95%;
  max-width: 900px;
  margin: 0 auto 1em;
}
#provide h4 {
  border-bottom: dotted 1px #c2c2c2;
  padding-bottom: 5px;
  margin-bottom: 0px;
}
#provide .half:nth-of-type(1) h4 {
  color: #02C3D9;
}
#provide .half:nth-of-type(2) h4 {
  color: #d9aa02;
}
#provide ul {
  max-width: 430px;
}
@media screen and (max-width: 480px) {
  #provide ul {
    width: 92%;
  }
}
#provide ul li ul {
  padding-left: 20px;
}
/* ======================================= */
/* ============= Top Vendors ============= */
/* ======================================= */
.top-vendor-container {
  margin: 70px auto 0;
  width: 90%;
  max-width: 900px;
}
@media only screen and (max-width: 420px) {
  .top-vendor-container {
    width: 100%;
  }
}
.top-vendor-container h1 {
  margin-top: 100px;
}
.top-vendor-container h3 {
  position: relative;
}
.top-vendor-container h3 span {
  position: absolute;
  bottom: 4px;
  right: 0;
  font-size: 16px;
}
.top-vendor-container h3 span a {
  color: #c2c2c2;
  text-decoration: none;
}
.top-vendor-container h3 span a:hover {
  cursor: pointer;
}
.top-vendor-container h2 {
  color: #d9aa02;
  font-size: 28px;
  margin-bottom: 20px;
}
.top-vendor-container ul {
  list-style-type: none;
  list-style-position: inherit;
  padding-left: 0px;
}
.top-vendor-container ul li {
  display: inline-block;
  width: 32%;
  margin-bottom: 10px;
}
@media only screen and (max-width: 602px) {
  .top-vendor-container ul li {
    font-size: 16px;
    line-height: 18px;
  }
}
@media only screen and (max-width: 420px) {
  .top-vendor-container ul li {
    width: 49%;
    margin-bottom: 15px;
  }
}
.top-vendor-container ul li a {
  text-decoration: none;
  color: #959595;
  height: 35px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
}
.top-vendor-container ul li a .state-list-icon,
.top-vendor-container ul li a .stateicon {
  height: 35px;
  min-width: 50px;
  margin-right: 10px;
  transition: all 0.3s;
}
@media only screen and (max-width: 420px) {
  .top-vendor-container ul li a .state-list-icon,
  .top-vendor-container ul li a .stateicon {
    margin-right: 5px;
  }
}
.top-vendor-container ul li a .stateicon {
  fill: #02C3D9;
}
.top-vendor-container ul li a:hover .stateicon {
  fill: #d9aa02;
}
@media only screen and (max-width: 480px) {
  .top-vendor-container {
    box-sizing: border-box;
    padding: 0 12px;
    margin-bottom: 20px;
  }
  .top-vendor-container h3 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
  }
  .top-vendor-container h3 span {
    bottom: -60px;
  }
  .top-vendor-container h2 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }
}
.top-vendor-container .vendor-list-container {
  margin: 100px 0px;
}
.top-vendor-container .vendor-list-container .followWrap {
  padding-bottom: 12px;
}
.top-vendor-container .vendor-list-container h3.followMeBar {
  padding: 50px 0 2px 0;
  margin: 0px;
  width: 100%;
  max-width: 900px;
  border-bottom: dotted 1px #7d7d7d;
  position: relative;
  z-index: 2;
  font-family: 'Montserrat', sans-serif;
  font-size: 3.6rem;
  font-weight: 700;
  color: #02C3D9;
}
@media only screen and (max-width: 1024px) {
  .top-vendor-container .vendor-list-container h3.followMeBar {
    max-width: 92vw;
    font-size: 2.6rem;
    padding-bottom: 4px;
  }
}
.top-vendor-container .vendor-list-container h3.followMeBar a.back-to-map {
  float: right;
  text-decoration: none;
  color: #959595;
  margin-top: 8px;
  font-family: 'Aleo', serif;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.033em;
  color: #d9aa02;
}
@media only screen and (max-width: 420px) {
  .top-vendor-container .vendor-list-container h3.followMeBar a.back-to-map {
    margin: 4px 4px 0 0;
    font-size: 1.3rem;
  }
}
.top-vendor-container .vendor-list-container h3.followMeBar.fixed {
  position: fixed;
  top: 0px;
  z-index: 2;
  background-color: #fff;
}
.top-vendor-container .vendor-list-container h3.followMeBar.fixed.absolute {
  position: absolute;
}
.top-vendor-container .vendor-list-container .vendor-state-container {
  margin-bottom: 25px;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: flex-start;
  -moz-align-content: flex-start;
  -ms-align-content: flex-start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  align-items: flex-start;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .icon {
  display: none;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .photographer {
  background-color: #d9aa02;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .photographer .vendor-icon {
  max-width: 45px;
  margin-top: -3%;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .florist {
  background-color: #d9aa02;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .florist .vendor-icon {
  max-width: 55px;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .planner {
  background-color: #d9aa02;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .planner .vendor-icon {
  max-width: 60px;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .stylist {
  background-color: #d9aa02;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container .stylist .vendor-icon {
  max-width: 50px;
  margin-right: -6%;
}
.top-vendor-container .vendor-list-container .vendor-detials-container .vendor-header-container h4 {
  box-sizing: border-box;
  display: block;
  width: calc(100% - 80px);
}
.top-vendor-container .vendor-list-container .vendor-detials-container p {
  margin: 10px 0 10px 0px;
}
@media only screen and (max-width: 420px) {
  .top-vendor-container .vendor-list-container .vendor-detials-container p {
    margin: 6px 0 0px 0px;
  }
}
.top-vendor-container .vendor-list-container .vendor-detials-container ul {
  margin: 10px 0 0px 0px;
}
@media only screen and (max-width: 420px) {
  .top-vendor-container .vendor-list-container .vendor-detials-container ul {
    margin: 10px 0 0px 0px;
  }
}
.top-vendor-container .vendor-list-container .vendor-detials-container ul li {
  display: inline;
  font-size: 15px;
  padding-right: 25px;
}
@media only screen and (max-width: 420px) {
  .top-vendor-container .vendor-list-container .vendor-detials-container ul li {
    display: block;
    width: 100%;
    margin-bottom: 6px;
  }
}

.form-error {
    color: #cc0000;
    margin: 0 auto;
    display: block;
    width: 90%;
    font-weight: 900;
    text-align: center;
}
